export const userConstants = {
    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    RESET_MESSAGE: "RESET_MESSAGE",

    GETUSER_REQUEST: "GETUSER_REQUEST",
    GETUSER_SUCCESS: "GETUSER_SUCCESS",
    GETUSER_FAILURE: "GETUSER_FAILURE",
};
