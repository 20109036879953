import { userConstants } from "./types/user.constants";
import { userService } from "../../services/user.service";
import { alertActions } from "./alert.action";

export const userActions = {
  login,
  getUserData,
  MicrosoftLogin,
};

function login(email, password, from) {
  return (dispatch) => {
    dispatch(request({ email }));

    userService.login(email, password).then(
      (user) => {
        if (user.code === 200) {
          let token = user.data.token;
          localStorage.setItem("token", token);
          localStorage.setItem("user_role", user.data.role);

          dispatch(success(user));

          window.location.href = "/";
        } else {
          dispatch(failure(user));
          setTimeout(() => {
            dispatch(resetMsg());
          }, 15000);
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(data) {
    return { type: userConstants.LOGIN_FAILURE, data };
  }
  function resetMsg() {
    return { type: userConstants.RESET_MESSAGE };
  }
}

function getUserData() {
  return (dispatch) => {
    dispatch(request());
    userService.getUserData().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETUSER_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETUSER_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETUSER_FAILURE, error };
  }
}
function MicrosoftLogin(email, from) {
  return (dispatch) => {
    dispatch(request({ email }));

    userService.MicrosoftLogin(email).then(
      (user) => {
        if (user.code === 200) {
          let token = user.data.token;
          localStorage.setItem("token", token);
          localStorage.setItem("user_role", user.data.role);

          dispatch(success(user));

          window.location.href = "/";
        } else {
          dispatch(failure(user));
          setTimeout(() => {
            dispatch(resetMsg());
          }, 15000);
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(data) {
    return { type: userConstants.LOGIN_FAILURE, data };
  }
  function resetMsg() {
    return { type: userConstants.RESET_MESSAGE };
  }
}
