import { useEffect, useState } from "react";
import { history } from "../helpers/history";
import nullAvatar from "../assets/gif/nullSideBarAvatar.gif";
import SettingIcon from "../assets/icons/Settingicon";
import LogoutIcon from "../assets/icons/logoutIcon";
import { userActions } from "../store/actions/user.actions";
import "reactjs-popup/dist/index.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { SsoSilentRequest } from "@azure/msal-browser";

const SideBarUser = ({ className, collapsed }) => {
  const dispatch = useDispatch();
  const user_dt = useSelector((state) => state.users);
  const { instance, accounts } = useMsal();

  // const logOut = () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("user_role");

  //   history.push("/login");
  //   window.location.href = "/login";
  // };
  const silentRequest = {
    account: accounts,
    forceLogout: true,
  };
  // const logOut = async () => {
  //   if (instance && accounts.length > 0) {
  //     instance.logoutPopup().then(() => {
  //       localStorage.clear();
  //       window.location.href = "/login";
  //     });
  //   } else {
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("user_role");
  //     history.push("/login");
  //     window.location.href = "/login";
  //   }
  // };
  // instance.logoutPopup().then(() => {

  const logOut = async () => {
    if (instance && accounts.length > 0) {
      localStorage.clear();
      window.location.href = "/login";
      // instance.logoutPopup().then(() => {
      //   localStorage.clear();
      //   window.location.href = "/login";
      // });
      // await instance.logout({
      //   account: instance.getActiveAccount(),
      //   mainWindowRedirectUri: "/",
      //   postLogoutRedirectUri: "/",
      //   navigateToLoginRequestUrl: false,
      //   // authority:
      //   //   "https://login.microsoftonline.com/c6f6dd74-a042-4f10-8258-6b311f5d8ccc",
      //   SsoSilent: true,
      //   prompt: null,
      // });

      // localStorage.clear();
      // window.location.href = "/login";
    } else {
      // instance.logout();
      localStorage.removeItem("token");
      localStorage.removeItem("user_role");
      history.push("/login");
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    dispatch(userActions.getUserData());
  }, []);

  return (
    // <Link className={className} to="/">
    <>
      <div className="side-bar__user">
        <div className="side-bar__user-img-wrapper">
          <img src={nullAvatar} alt="user avatar" />
        </div>
        <span className="user-clr text-sm font-semibold">
          {collapsed
            ? null
            : user_dt.items !== undefined
            ? user_dt.items.name
            : null}
        </span>
        {/* {user_dt.items !== undefined ? user_dt.items.name : "Anil G."} */}
        {collapsed ? null : (
          <p className="user__name">
            <span>
              <Dropdown>
                <Dropdown.Toggle>
                  <i className="fa fa-bars fnt-24 " aria-hidden="true"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    href="/change-password"
                    className="padd-10 user-clr text-sm font-semibold"
                  >
                    <SettingIcon /> Settings
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={logOut}
                    className="padd-10 user-clr text-sm font-semibold"
                  >
                    <LogoutIcon />
                    <span>Logout</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </p>
        )}
        {/* </div> */}
      </div>
    </>
  );
};

export default SideBarUser;
