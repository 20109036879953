import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { timesheetActions } from "../../store/actions/timeSheet.actions";

const statusList = [
  { label: "Pending", value: "Pending", type: "sheetStatus" ,clr:"#e8635f"},
  { label: "Approved", value: "Approved", type: "sheetStatus",clr:"#44b263" },
  {
    label: "Request Clarification",
    value: "Request Clarification",
    type: "sheetStatus",
    clr:"#cb8a03"
  },
];

export function Status({updatePageNo}) {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(null);
  const handleStatusChange = (status_type) => {
    updatePageNo(1)
    localStorage.setItem("sheetStatus", status_type);
    dispatch(
      timesheetActions.getTimesheetlist(
        status_type,
                  localStorage.getItem("sheet_start_date")
              ? localStorage.getItem("sheet_start_date")
              : null,
          localStorage.getItem("sheet_end_date")
              ? localStorage.getItem("sheet_end_date")
              : null,
              localStorage.getItem("tms_search")
          ? localStorage.getItem("tms_search")
          : null,
          localStorage.getItem("sheetComapny")
          ? localStorage.getItem("sheetComapny")
          : null,
          1,
          localStorage.getItem("VehicleCode")
          ? localStorage.getItem("VehicleCode")
          : null
      )
  );
  };
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-2 min-w-[10rem]">
            <Listbox.Button className="f-btn relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#FCAF17] sm:text-sm sm:leading-6">
              <span className="flex items-center space-x-1.5 truncate">
                {!localStorage.getItem("sheetStatus") && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M7 15h6v-1.5H7V15Zm0-3h6v-1.5H7V12Zm-1.506 6c-.413 0-.765-.147-1.056-.44A1.45 1.45 0 0 1 4 16.5v-13c0-.413.147-.766.44-1.06.294-.293.647-.44 1.06-.44H12l4 4v10.5c0 .413-.147.766-.44 1.06-.295.293-.648.44-1.06.44H5.493ZM11 7V3.5H5.5v13h9V7H11Z"
                    />
                  </svg>
                )}
                <span className="fs-14">{selected && localStorage.getItem("sheetStatus") ? selected : "Status"}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="hw-2 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="fs-14 absolute z-10 mt-1 max-h-dp w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {statusList.map((item) => (
                  <Listbox.Option
                    title={item.label}
                    key={item.label}
                    className={({ active }) =>
                      clsx(
                        active ? "bg-gray-50" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    style={{color:item.clr}}
                    value={item.value}
                    onClick={()=>handleStatusChange(item.value)}
                  >
                    {({ selected }) => (
                      <div className="flex items-center space-x-2">
                        <span
                        // "bg-[#FCAF17]"
                        style={{backgroundColor: item.clr}}


                          className={clsx(
                            selected && localStorage.getItem("sheetStatus")? "bg-[#FCAF17]"   : "bg-gray-200",
                            "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                          )}
                        />
                        <span
                          className={clsx(
                            selected && localStorage.getItem("sheetStatus") ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item.value}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
