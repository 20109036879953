export const timesheetConstants = {
    DASHBOARD_GRAPH_REQUEST: "DASHBOARD_GRAPH_REQUEST",
    DASHBOARD_GRAPH_REQUEST_STOP: "DASHBOARD_GRAPH_REQUEST_STOP",
    DASHBOARD_GRAPH_SUCCESS: "DASHBOARD_GRAPH_SUCCESS",
    DASHBOARD_GRAPH_FAILURE: "DASHBOARD_GRAPH_FAILURE",

    UPDATE_TIMESHEET_STATUS_REQUEST: "UPDATE_TIMESHEET_STATUS_REQUEST",
    UPDATE_TIMESHEET_STATUS_REQUEST_STOP:
        "UPDATE_TIMESHEET_STATUS_REQUEST_STOP",
    UPDATE_TIMESHEET_STATUS_SUCCESS: "UPDATE_TIMESHEET_STATUS_SUCCESS",
    UPDATE_TIMESHEET_STATUS_FAILURE: "UPDATE_TIMESHEET_STATUS_FAILURE",

    UPDATE_TIMESHEET_REQUEST: "UPDATE_TIMESHEET_REQUEST",
    UPDATE_TIMESHEET_REQUEST_STOP: "UPDATE_TIMESHEET_REQUEST_STOP",
    UPDATE_TIMESHEET_SUCCESS: "UPDATE_TIMESHEET_SUCCESS",
    UPDATE_TIMESHEET_FAILURE: "UPDATE_TIMESHEET_FAILURE",

    TIMESHEET_REQUEST: "TIMESHEET_REQUEST",
    TIMESHEET_REQUEST_STOP: "TIMESHEETREQUEST_STOP",
    TIMESHEET_SUCCESS: "TIMESHEET_SUCCESS",
    TIMESHEET_FAILURE: "TIMESHEET_FAILURE",

    GET_TIMESHEET_REQUEST: "GET_TIMESHEET_REQUEST",
    GET_TIMESHEET_REQUEST_STOP: "GET_TIMESHEETREQUEST_STOP",
    GET_TIMESHEET_SUCCESS: "GET_TIMESHEET_SUCCESS",
    GET_TIMESHEET_FAILURE: "GET_TIMESHEET_FAILURE",

    GET_BASE64_REQUEST: "GET_BASE64_REQUEST",
    GET_BASE64_REQUEST_STOP: "GET_BASE64_REQUEST_STOP",
    GET_BASE64_SUCCESS: "GET_BASE64_SUCCESS",
    GET_BASE64_FAILURE: "GET_BASE64_FAILURE",

    FILEDATA_REQUEST: "FILEDATA_REQUEST",
    FILEDATA_REQUEST_STOP: "FILEDATA_STOP",
    FILEDATA_SUCCESS: "FILEDATA_SUCCESS",
    FILEDATA_FAILURE: "FILEDATA_FAILURE",

    GET_MACHINE_REQUEST: "GET_MACHINE_REQUEST",
    GET_MACHINE_REQUEST_STOP: "GET_MACHINE_REQUEST_STOP",
    GET_MACHINE_SUCCESS: "GET_MACHINE_SUCCESS",
    GET_MACHINE_FAILURE: "GET_MACHINE_FAILURE",

    GET_SHEET_BY_ID_REQUEST: "GET_SHEET_BY_ID_REQUEST",
    GET_SHEET_BY_ID_REQUEST_STOP: "GET_SHEET_BY_ID_REQUEST_STOP",
    GET_SHEET_BY_ID_SUCCESS: "GET_SHEET_BY_ID_SUCCESS",
    GET_SHEET_BY_ID_FAILURE: "GET_SHEET_BY_ID_FAILURE",

    GET_MESSAGE_REQUEST: "GET_MESSAGE_REQUEST",
    GET_MESSAGE_REQUEST_STOP: "GET_MESSAGE_REQUEST_STOP",
    GET_MESSAGE_SUCCESS: "GET_MESSAGE_SUCCESS",
    GET_MESSAGE_FAILURE: "GET_MESSAGE_FAILURE",

    GET_OPTIONS_REQUEST: "GET_OPTIONS_REQUEST",
    GET_OPTIONS_REQUEST_STOP: "GET_OPTIONS_REQUEST_STOP",
    GET_OPTIONS_SUCCESS: "GET_OPTIONS_SUCCESS",
    GET_OPTIONS_FAILURE: "GET_OPTIONS_FAILURE",

    SHEET_APPROVE_AUDIT_REQUEST: "SHEET_APPROVE_AUDIT_REQUEST",
    SHEET_APPROVE_AUDIT_REQUEST_STOP: "SHEET_APPROVE_AUDIT_REQUEST_STOP",
    SHEET_APPROVE_AUDIT_SUCCESS: "SHEET_APPROVE_AUDIT_SUCCESS",
    SHEET_APPROVE_AUDIT_FAILURE: "SHEET_APPROVE_AUDIT_FAILURE",
};
