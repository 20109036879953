import React from "react";
import classNames from "classnames";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
const PaginationCustom = (props) => {
  return (
    // <div className={classNames("pagination", props.className)}>
    //     <button
    //         className={classNames(
    //             "pagination__btn",
    //             "prev",
    //             props.classNamePrev
    //         )}
    //         onClick={props.onClickPrev}
    //         disabled={props.disabledPrev}
    //     >
    //         &#171; prev {props.btnText}
    //     </button>
    //     {props.children}
    //     <button
    //         className={classNames(
    //             "pagination__btn",
    //             "next",
    //             props.classNameNext
    //         )}
    //         onClick={props.onClickNext}
    //         disabled={props.disabledNext}
    //     >
    //         next &#187; {props.btnText}
    //     </button>
    // </div>
    <div className={classNames("pagination2", props.className)}>
      {/* <button
            className={classNames(
                "pagination__btn",
                "prev",
                props.classNamePrev
            )}
            onClick={props.onClickPrev}
            disabled={props.disabledPrev}
        >
            &#171; prev {props.btnText}
        </button> */}
      <a className="crsr-pointer relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400">
        <span className="sr-only">Previous</span>
        <ChevronLeftIcon
          className="hw-2 hw-2"
          aria-hidden="true"
          onClick={props.onClickPrev}
          disabled={props.disabledPrev}
        />
      </a>
      {props.children}
      <a className="crsr-pointer relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400">
        <span className="sr-only">Next</span>
        <ChevronRightIcon
          className="hw-2 hw-2"
          aria-hidden="true"
          onClick={props.onClickNext}
          disabled={props.disabledNext}
        />
      </a>
      {/* <button
        className={classNames("pagination__btn", "next", props.classNameNext)}
        onClick={props.onClickNext}
        disabled={props.disabledNext}
      >
        next &#187; {props.btnText}
      </button> */}
    </div>
  );
};

export default PaginationCustom;
