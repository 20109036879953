import { Disclosure } from "@headlessui/react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { EyeIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState, useEffect } from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
// import Dropdown from "rsuite/Dropdown";
import { useSelector } from "react-redux";

import logo from "../images/logo.png";
import SideBarUser from "./SideBarUser";
import { useWindowSize } from "../hooks/useWindowSize";
import supportIcon from "../assets/icons/helpAndSupport.svg";
const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [path, setPath] = useState("/");

  const openNewTab = () => {
    window.open("/help-and-support");
  };

  useEffect(() => {
    setPath(window.location.pathname);
    setUserRole(
      localStorage.getItem("user_role")
        ? localStorage.getItem("user_role")
        : null
    );
  }, []);
  return (
    <>
      <Sidebar
        className="side-bar"
        width="280px"
        backgroundColor="#ffffff"
        collapsedWidth="80px"
        collapsed={collapsed}
      >
        <div
          className="side-bar__logo"
          style={{ paddingInline: `${collapsed ? "1px" : "0px"}` }}
        >
          <Link to="/">
            <img
              className={collapsed ? "wdth-110" : null}
              src={logo}
              alt="Logo"
            />
          </Link>
        </div>
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="side-bar__btn brdr-none"
          style={{
            transform: `${collapsed ? "rotate(-180deg)" : "none"}`,
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.58342 10L9.58341 5L10.4792 5.89583L6.35425 10L10.4584 14.1042L9.56258 15L4.58342 10Z"
              fill="#657488"
            />
            <path
              d="M9.16691 10L14.1669 5L15.0627 5.89583L10.9377 10L15.0419 14.1042L14.1461 15L9.16691 10Z"
              fill="#657488"
            />
          </svg>
        </button>
        <Menu>
          <MenuItem
            className="side-bar__menu-item text-sm font-semibold"
            // className="group mx-auto flex gap-x-3 rounded-md px-6 py-2 text-sm font-semibold leading-6 text-gray-500"
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z"
                  fill="#657488"
                />
                <path
                  d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z"
                  fill="#657488"
                />
                <path
                  d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z"
                  fill="#657488"
                />
                <path
                  d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
                  fill="#657488"
                />
              </svg>
            }
            component={<Link to="/dashboard" />}
          >
            Dashboard
          </MenuItem>

          {userRole
            ? (userRole === "CFO" ||
                userRole === "Contractor" ||
                userRole === "Subcontractor" ||
                userRole === "Approver") && (
                <MenuItem
                  className="side-bar__menu-item text-sm font-semibold"
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      // xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 18H16V16H8V18ZM8 14H16V12H8V14ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H14L20 8V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 9V4H6V20H18V9H13Z"
                        fill="#657488"
                      />
                    </svg>
                  }
                  component={<Link to="/view-document" />}
                >
                  Finance Data
                </MenuItem>
              )
            : null}
          {userRole
            ? (userRole === "Contractor" ||
                userRole === "CFO" ||
                userRole === "Approver") && (
                <MenuItem
                  className="side-bar__menu-item text-sm font-semibold"
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      // xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 9C8.28333 9 8.52083 8.90417 8.7125 8.7125C8.90417 8.52083 9 8.28333 9 8C9 7.71667 8.90417 7.47917 8.7125 7.2875C8.52083 7.09583 8.28333 7 8 7C7.71667 7 7.47917 7.09583 7.2875 7.2875C7.09583 7.47917 7 7.71667 7 8C7 8.28333 7.09583 8.52083 7.2875 8.7125C7.47917 8.90417 7.71667 9 8 9ZM8 13C8.28333 13 8.52083 12.9042 8.7125 12.7125C8.90417 12.5208 9 12.2833 9 12C9 11.7167 8.90417 11.4792 8.7125 11.2875C8.52083 11.0958 8.28333 11 8 11C7.71667 11 7.47917 11.0958 7.2875 11.2875C7.09583 11.4792 7 11.7167 7 12C7 12.2833 7.09583 12.5208 7.2875 12.7125C7.47917 12.9042 7.71667 13 8 13ZM8 17C8.28333 17 8.52083 16.9042 8.7125 16.7125C8.90417 16.5208 9 16.2833 9 16C9 15.7167 8.90417 15.4792 8.7125 15.2875C8.52083 15.0958 8.28333 15 8 15C7.71667 15 7.47917 15.0958 7.2875 15.2875C7.09583 15.4792 7 15.7167 7 16C7 16.2833 7.09583 16.5208 7.2875 16.7125C7.47917 16.9042 7.71667 17 8 17ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H16L21 8V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V9H15V5H5V19Z"
                        fill="#657488"
                      />
                    </svg>
                  }
                  component={<Link to="/reports" />}
                >
                  Reports
                </MenuItem>
              )
            : null}
          {userRole
            ? (userRole === "CFO" || userRole === "Approver") && (
                <MenuItem
                  className="side-bar__menu-item text-sm font-semibold"
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      // xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.3499 16.25L16.6249 14.975L12.8999 11.25V6.00002H11.0999V12L15.3499 16.25ZM11.9999 21.6C10.677 21.6 9.43374 21.35 8.2702 20.85C7.10667 20.35 6.0874 19.6625 5.2124 18.7875C4.3374 17.9125 3.6499 16.892 3.1499 15.7261C2.6499 14.5601 2.3999 13.3143 2.3999 11.9886C2.3999 10.6629 2.6499 9.41669 3.1499 8.25002C3.6499 7.08336 4.3374 6.06669 5.2124 5.20002C6.0874 4.33336 7.10789 3.65002 8.27385 3.15002C9.43984 2.65002 10.6857 2.40002 12.0114 2.40002C13.3371 2.40002 14.5833 2.65202 15.7501 3.15602C16.9168 3.66002 17.9318 4.34402 18.7949 5.20802C19.658 6.07202 20.3412 7.08802 20.8447 8.25602C21.3482 9.42402 21.5999 10.672 21.5999 12C21.5999 13.323 21.3499 14.5662 20.8499 15.7297C20.3499 16.8933 19.6666 17.9125 18.7999 18.7875C17.9332 19.6625 16.9155 20.35 15.7466 20.85C14.5778 21.35 13.3289 21.6 11.9999 21.6ZM12.0118 19.8C14.1698 19.8 16.0073 19.0375 17.5243 17.5125C19.0414 15.9875 19.7999 14.146 19.7999 11.9881C19.7999 9.83015 19.0414 7.99265 17.5243 6.4756C16.0073 4.95855 14.1698 4.20002 12.0118 4.20002C9.85388 4.20002 8.0124 4.95855 6.4874 6.4756C4.9624 7.99265 4.1999 9.83015 4.1999 11.9881C4.1999 14.146 4.9624 15.9875 6.4874 17.5125C8.0124 19.0375 9.85388 19.8 12.0118 19.8Z"
                        fill="#657488"
                      />
                    </svg>
                  }
                  component={<Link to="/list-timesheet" />}
                >
                  View Timesheets
                </MenuItem>
              )
            : null}
          {userRole
            ? (userRole === "CFO" || userRole === "Approver") && (
                <MenuItem
                  className="side-bar__menu-item text-sm font-semibold"
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      // xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_79_389)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.2868 18.1066H7.71135C6.79635 18.1066 6.05835 18.9781 6.05835 20.0566V22.0051C6.05835 23.0821 6.79485 23.9536 7.71135 23.9536H22.2868C23.1988 23.9536 23.9383 23.0821 23.9383 22.0051V20.0566C23.9383 18.9781 23.2003 18.1066 22.2868 18.1066ZM10.4683 22.6576C9.54585 22.6576 8.79585 21.8986 8.79585 20.9611C8.79585 20.0206 9.54585 19.2631 10.4683 19.2631C11.3878 19.2631 12.1378 20.0206 12.1378 20.9611C12.1378 21.9001 11.3878 22.6576 10.4683 22.6576ZM14.9788 22.6411C14.0878 22.6411 13.3648 21.9166 13.3648 21.0211C13.3648 20.1256 14.0878 19.3996 14.9788 19.3996C15.8683 19.3996 16.5928 20.1256 16.5928 21.0211C16.5928 21.9166 15.8683 22.6411 14.9788 22.6411ZM19.5463 22.6891C18.5998 22.6891 17.8288 21.9466 17.8288 21.0271C17.8288 20.1046 18.5998 19.3606 19.5463 19.3606C20.4928 19.3606 21.2638 20.1046 21.2638 21.0271C21.2638 21.9466 20.4928 22.6891 19.5463 22.6891Z"
                          fill="#657488"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.9669 12.0315L17.9984 9.01053H11.1254L9.87588 5.86503L9.86988 5.84703L9.86238 5.85003L5.98338 1.80603L6.50538 0.456026L5.20938 -0.0479736L1.82238 9.37653C0.164883 10.3095 -0.465117 12.408 0.418383 14.103C1.31838 15.822 3.87288 16.992 5.57838 16.0755C6.89988 15.3675 4.79838 10.968 3.19488 9.67203L5.42838 3.24303L8.67138 6.62553L10.5929 13.503C10.5929 13.503 10.5929 12.5865 10.5929 14.997C10.5929 17.4075 12.1394 16.413 12.1394 16.413H21.1859C21.1859 16.413 22.4519 16.998 22.4519 14.9025V13.074C22.4519 11.7735 21.0449 12.012 21.0449 12.012L17.9669 12.0315ZM16.5464 15.093H11.9054V10.4055H16.5464V15.093Z"
                          fill="#657488"
                        />
                      </g>
                    </svg>
                  }
                  component={<Link to="/machines" />}
                >
                  View Machines
                </MenuItem>
              )
            : null}

          {userRole
            ? (userRole === "Subcontractor" || userRole === "Contractor") && (
                <SubMenu
                  className="side-bar__menu-item text-sm font-semibold"
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      // xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.3499 16.25L16.6249 14.975L12.8999 11.25V6.00002H11.0999V12L15.3499 16.25ZM11.9999 21.6C10.677 21.6 9.43374 21.35 8.2702 20.85C7.10667 20.35 6.0874 19.6625 5.2124 18.7875C4.3374 17.9125 3.6499 16.892 3.1499 15.7261C2.6499 14.5601 2.3999 13.3143 2.3999 11.9886C2.3999 10.6629 2.6499 9.41669 3.1499 8.25002C3.6499 7.08336 4.3374 6.06669 5.2124 5.20002C6.0874 4.33336 7.10789 3.65002 8.27385 3.15002C9.43984 2.65002 10.6857 2.40002 12.0114 2.40002C13.3371 2.40002 14.5833 2.65202 15.7501 3.15602C16.9168 3.66002 17.9318 4.34402 18.7949 5.20802C19.658 6.07202 20.3412 7.08802 20.8447 8.25602C21.3482 9.42402 21.5999 10.672 21.5999 12C21.5999 13.323 21.3499 14.5662 20.8499 15.7297C20.3499 16.8933 19.6666 17.9125 18.7999 18.7875C17.9332 19.6625 16.9155 20.35 15.7466 20.85C14.5778 21.35 13.3289 21.6 11.9999 21.6ZM12.0118 19.8C14.1698 19.8 16.0073 19.0375 17.5243 17.5125C19.0414 15.9875 19.7999 14.146 19.7999 11.9881C19.7999 9.83015 19.0414 7.99265 17.5243 6.4756C16.0073 4.95855 14.1698 4.20002 12.0118 4.20002C9.85388 4.20002 8.0124 4.95855 6.4874 6.4756C4.9624 7.99265 4.1999 9.83015 4.1999 11.9881C4.1999 14.146 4.9624 15.9875 6.4874 17.5125C8.0124 19.0375 9.85388 19.8 12.0118 19.8Z"
                        fill="#657488"
                      />
                    </svg>
                  }
                  label="Time Sheets"
                >
                  <MenuItem component={<Link to="/list-timesheet" />}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="view-icon h-8 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      ></path>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      ></path>
                    </svg>
                    View Timesheets
                  </MenuItem>
                  <MenuItem component={<Link to="/save-timesheet" />}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="add-icon h-8 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    Add Timesheets
                  </MenuItem>
                </SubMenu>
              )
            : null}
          {userRole
            ? (userRole === "Subcontractor" || userRole === "Contractor") && (
                <SubMenu
                  className="side-bar__menu-item text-sm font-semibold"
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      // xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_79_389)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.2868 18.1066H7.71135C6.79635 18.1066 6.05835 18.9781 6.05835 20.0566V22.0051C6.05835 23.0821 6.79485 23.9536 7.71135 23.9536H22.2868C23.1988 23.9536 23.9383 23.0821 23.9383 22.0051V20.0566C23.9383 18.9781 23.2003 18.1066 22.2868 18.1066ZM10.4683 22.6576C9.54585 22.6576 8.79585 21.8986 8.79585 20.9611C8.79585 20.0206 9.54585 19.2631 10.4683 19.2631C11.3878 19.2631 12.1378 20.0206 12.1378 20.9611C12.1378 21.9001 11.3878 22.6576 10.4683 22.6576ZM14.9788 22.6411C14.0878 22.6411 13.3648 21.9166 13.3648 21.0211C13.3648 20.1256 14.0878 19.3996 14.9788 19.3996C15.8683 19.3996 16.5928 20.1256 16.5928 21.0211C16.5928 21.9166 15.8683 22.6411 14.9788 22.6411ZM19.5463 22.6891C18.5998 22.6891 17.8288 21.9466 17.8288 21.0271C17.8288 20.1046 18.5998 19.3606 19.5463 19.3606C20.4928 19.3606 21.2638 20.1046 21.2638 21.0271C21.2638 21.9466 20.4928 22.6891 19.5463 22.6891Z"
                          fill="#657488"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.9669 12.0315L17.9984 9.01053H11.1254L9.87588 5.86503L9.86988 5.84703L9.86238 5.85003L5.98338 1.80603L6.50538 0.456026L5.20938 -0.0479736L1.82238 9.37653C0.164883 10.3095 -0.465117 12.408 0.418383 14.103C1.31838 15.822 3.87288 16.992 5.57838 16.0755C6.89988 15.3675 4.79838 10.968 3.19488 9.67203L5.42838 3.24303L8.67138 6.62553L10.5929 13.503C10.5929 13.503 10.5929 12.5865 10.5929 14.997C10.5929 17.4075 12.1394 16.413 12.1394 16.413H21.1859C21.1859 16.413 22.4519 16.998 22.4519 14.9025V13.074C22.4519 11.7735 21.0449 12.012 21.0449 12.012L17.9669 12.0315ZM16.5464 15.093H11.9054V10.4055H16.5464V15.093Z"
                          fill="#657488"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_79_389">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  }
                  // component={<Link to="/machines" />}
                  label="Machines"
                >
                  <MenuItem component={<Link to="/machines" />}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="view-icon h-8 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      ></path>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      ></path>
                    </svg>
                    View Machines
                  </MenuItem>
                  <MenuItem component={<Link to="/add-machine" />}>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="add-icon h-8 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    Add machines
                  </MenuItem>
                </SubMenu>
              )
            : null}
          {userRole
            ? userRole === "CFO" && (
                <MenuItem
                  className="side-bar__menu-item text-sm font-semibold"
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9998 9.32C13.1898 9.32 14.1598 8.35 14.1598 7.16C14.1598 5.97 13.1898 5 11.9998 5C10.8098 5 9.83984 5.97 9.83984 7.16C9.83984 8.35 10.8098 9.32 11.9998 9.32Z"
                        stroke="#657488"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.78988 18.9999C7.97988 18.9999 8.94988 18.0299 8.94988 16.8399C8.94988 15.6499 7.97988 14.6799 6.78988 14.6799C5.59988 14.6799 4.62988 15.6499 4.62988 16.8399C4.62988 18.0299 5.58988 18.9999 6.78988 18.9999Z"
                        stroke="#657488"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.2098 18.9999C18.3998 18.9999 19.3698 18.0299 19.3698 16.8399C19.3698 15.6499 18.3998 14.6799 17.2098 14.6799C16.0198 14.6799 15.0498 15.6499 15.0498 16.8399C15.0498 18.0299 16.0198 18.9999 17.2098 18.9999Z"
                        stroke="#657488"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  component={<Link to="/anomalies" />}
                >
                  Anomalies
                </MenuItem>
              )
            : null}

          <MenuItem
            className="side-bar__menu-item text-sm font-semibold"
            onClick={openNewTab}
            icon={<img src={supportIcon} className="wdth-80" alt="doctype" />}
          >
            Help & Support
          </MenuItem>
        </Menu>
        <SideBarUser className="side-bar__user" collapsed={collapsed} />
      </Sidebar>
    </>
  );
};

export default SideBar;
