import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Dispatch, Fragment, SetStateAction } from "react";

export function Modal({ open, setOpen, machine_data }) {
  const machineData = machine_data?machine_data:null
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-4xl transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <form>
                  <div className="space-y-6">
                    <div>
                      <div className="flex items-center justify-between pb-4">
                        <h2 className="text-lg font-semibold leading-7 text-gray-900"  style={{ padding: '10px', fontSize: 'large' }}>
                          Machine Details
                        </h2>
                        <button type="button" onClick={() => setOpen(false)}>
                          <XMarkIcon className="hw-2" />
                        </button>
                      </div>

                      <hr className="-mx-6" />

                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-type"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Machine Type
                          </label>
                          <div className="mt-2">
                            <input
disabled
                              type="text"
                              name="machine-type"
                              id="machine-type"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Articulated Dump Truck"
                              value={machineData && machineData.machine_type?machineData.machine_type:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-subtype"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Machine Subtype
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="machine-subtype"
                              id="machine-subtype"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="6*4"
                              value={machineData && machineData.machine_sub_type?machineData.machine_sub_type:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-year"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Machine Year
                          </label>
                          <div className="mt-2">
                            <input
                                disabled
                              type="text"
                              name="machine-year"
                              id="machine-year"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="2020"
                              value={machineData && machineData.year?machineData.year:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="make"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Make
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="make"
                              id="make"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Bell"
                              value={machineData && machineData.make?machineData.make:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-age"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Machine Age
                          </label>
                          <div className="mt-2">
                            <input
                                disabled
                              type="text"
                              name="machine-age"
                              id="machine-age"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="3"
                              value={machineData && machineData.machine_age?machineData.machine_age:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-model"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Machine Model
                          </label>
                          <div className="mt-2">
                            <input
                            disabled
                              type="text"
                              name="machine-model"
                              id="machine-model"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="B20B"
                              value={machineData && machineData.model?machineData.model:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="imei-number"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            IMEI Number
                          </label>
                          <div className="mt-2">
                            <input
                            disabled
                              type="text"
                              name="imei-number"
                              id="imei-number"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="1236547896325"
                              value={machineData && machineData.imei_no?machineData.imei_no:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="vehicle-code"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Vehicle Code
                          </label>
                          <div className="mt-2">
                            <input
                            disabled
                              type="text"
                              name="vehicle-code"
                              id="vehicle-code"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="B7"
                              value={machineData && machineData.vehicle_code?machineData.vehicle_code:'N/A'}
                            />
                          </div>
                        </div>

                        {/* <div className="sm:col-span-3">
                          <label
                            htmlFor="contractor-company"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Contractor Company
                          </label>
                          <div className="mt-2">
                            <input
                            disabled
                              type="text"
                              name="contractor-company"
                              id="contractor-company"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Red Top Asset Management"
                              value={machineData && machineData.contractor_company?machineData.contractor_company:'N/A'}
                            />
                          </div> */}
                        {/* </div> */}

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Machine Description
                          </label>
                          <div className="mt-2">
                            <input
                            disabled
                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={machineData && machineData.discription?machineData.discription:'N/A'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <button
                    type="button"
                    className="focus-visible:outline-bg-[#FCAF17] mt-6 inline-flex w-full justify-center rounded-md bg-[#FCAF17] px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    Save
                  </button> */}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
