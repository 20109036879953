import { useState } from "react";
import NavBar from "./NavBar";
import { MDBBtn } from "mdb-react-ui-kit";
import { history } from "../helpers/history";
import axios from "axios";
import * as config from "../config/config";
import getOptions from "../services/http.header";
import SideBar from "./SideBar";

function ChangePassword() {
  const expired_session = window.location.href.split("period=")[1];
  const [addingLoader, setAddingLoader] = useState(false);
  const [validationMsg, setValidationMsg] = useState(null);
  const [showValidationMsg, setShowValidationMsg] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };
  const [newPassword, setNewPassword] = useState(null);
  
  const handleNewPasswordChange = (e) => {
    setValidationMsg(null);
    setShowValidationMsg(false);
    const password = e.target.value;

    if (password.length < 8) {
      setValidationMsg("Password must be at least 8 characters.");
      setShowValidationMsg(true);
      setIsError(true);
    } else if (!/[A-Z]/.test(password)) {
      setValidationMsg("Password must contain at least one uppercase letter.");
      setShowValidationMsg(true);
      setIsError(true);
    } else if (!/[a-z]/.test(password)) {
      setValidationMsg("Password must contain at least one lowercase letter.");
      setShowValidationMsg(true);
      setIsError(true);
    } else if (!/\d/.test(password)) {
      setValidationMsg("Password must contain at least one numeric character.");
      setShowValidationMsg(true);
      setIsError(true);
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setValidationMsg("Password must contain at least one special character.");
      setShowValidationMsg(true);
      setIsError(true);
    } else {
      setValidationMsg(null);
      setShowValidationMsg(false);
      setIsError(false);
      setNewPassword(password);
    }
  };

  const [confirmPassword, setConfirmPassword] = useState(null);
  const handleConfirmPasswordChange = (e) => {
    if (newPassword === e.target.value) {
      setConfirmPassword(e.target.value);
      setIsError(false);
      setMsg(null);
    } else {
      setMsg("New password and confirm password must be the same.");
      setIsError(true);
    }
  };

  const logOut = () => {
    localStorage.removeItem("token");
    history.push("/login");
    window.location.href = "/login";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAddingLoader(true);
    setIsError(false);
    setMsg(null);

    if (newPassword === confirmPassword) {
      if (newPassword && confirmPassword && oldPassword) {
        let data_dict = {
          confirm_password: confirmPassword,
          new_password: newPassword,
          old_password: oldPassword,
        };
        axios
          .post(
            `${config.apiUrl}/users/change-paasword/`,
            data_dict,
            getOptions()
          )
          .then((response) => {
            setAddingLoader(false);

            if (response.data.code === 200) {
              setMsg(response.data.message);
              setTimeout(() => {
                logOut();
              }, 5000);
            } else {
              setMsg(response.data.message);
              setIsError(true);
            }
          })
          .catch((err) => {
            setAddingLoader(false);
            return err;
          });
      } else {
        setAddingLoader(false);
      }
    } else {
      setMsg("New password and confirm password must be the same.");
      setIsError(true);
    }
  };

  return (
    <>
      {/* <NavBar /> */}
      <main className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row m-top-40" style={{ display: 'flex' }}>
        <SideBar />
        <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row border-box d-flex document-wifth mx-auto ml-0 mr-0 mt-20 pl-0 pr-0"
          style={{ left: '50px' }}>
          <div className="main__container p-0">
            {expired_session && expired_session === "expired" ? (
              <p className="update-pass-msg">
                Please update your password as it must be changed every 42 days
                for security purposes.
              </p>
            ) : null}
            <p className="password-head m-0 p-0 text-center">Reset Password</p>
            <div className="col-sm-4 col-lg-4 col-xs-4 col-md-4 row d-flex m-0 mx-auto p-0">
              <div className="m-0 mt-3">
                <label className="input-heading-cstm2 m-0">
                  Current Password :
                </label>
                <input
                  type="password"
                  placeholder="Current password "
                  name="new_password"
                  defaultValue={oldPassword}
                  onChange={handleOldPasswordChange}
                  className={
                    "block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                  }
                />
              </div>
              <div className="m-0 mt-3">
                <label className="input-heading-cstm2 m-0">
                  New Password :
                </label>
                <input
                  type="password"
                  placeholder="New Password"
                  name="new_password"
                  defaultValue={newPassword}
                  onChange={handleNewPasswordChange}
                  className={
                    "block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                  }
                />
              </div>

              <div className="m-0 mt-3">
                <label className="input-heading-cstm2 m-0">
                  Confirm Password :
                </label>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  name="confirm_password"
                  defaultValue={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  className={
                    "block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                  }
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 d-flex m-0 m-0 mx-auto mt-3 p-0" style={{ justifyContent: 'center', display: 'flex' }}>
              <div className="col-lg-7 col-md-7 col-xs-7 col-sm-7 d-flex m-0 m-0 mx-auto mt-3 p-0">
                {isError && validationMsg !== null && (
                  <div className="alert alert-danger fnt-fmly-popin password-msg">
                    {validationMsg}
                  </div>
                )}
                {isError && validationMsg === null && (
                  <div className="alert alert-danger fnt-fmly-popin password-msg">
                    {msg}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 d-flex mr-b-30 m-0 mx-auto mt-3 p-0">
              <MDBBtn
                className={
                  oldPassword && newPassword && confirmPassword
                    ? "password-btn"
                    : "password-btn-disabled"
                }
                disabled={
                  oldPassword && newPassword && confirmPassword ? false : true
                }
                onClick={(event) => handleSubmit(event)}
              >
                {addingLoader ? (
                  <span className="fa fa-spinner fa-spin mr-1"></span>
                ) : (
                  "Reset Password"
                )}
              </MDBBtn>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export { ChangePassword };
