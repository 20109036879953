// import NavBar from "./NavBar";
import { useState, useEffect } from "react";
import SideBar from "./SideBar";
import { useDispatch, useSelector } from "react-redux";
function Reports() {
  const user_dt = useSelector((state) => state.users);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    setUserRole(
      localStorage.getItem("user_role")
        ? localStorage.getItem("user_role")
        : null
    );
  }, []);

  // const klt_src =
  //   "https://app.powerbi.com/groups/246cb3ef-1cb1-4234-8319-9d0fb290b495/reports/012a8271-3cde-4962-a399-327d5c80c9d8?ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc&pbi_source=linkShare";
  // const red_t_src =
  //   "https://app.powerbi.com/groups/246cb3ef-1cb1-4234-8319-9d0fb290b495/reports/edcf07d0-9ba6-4a6c-9a8a-24a9f60b0192?ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc&pbi_source=linkShare";
  // const qrt_src =
  //   "https://app.powerbi.com/groups/246cb3ef-1cb1-4234-8319-9d0fb290b495/reports/c88542a5-dcf4-4f15-b229-8171f6c05587?ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc&pbi_source=linkShare";
  // const [iframeSource, setIframeSource] = useState(null);
  // useEffect(() => {
  //   if (user_dt) {
  //     if (user_dt.items !== undefined) {
  //       if (user_dt.items.company_name) {
  //         if (user_dt.items.company_name === "Quartz Plant Hire") {
  //           setIframeSource(qrt_src);
  //         } else if (
  //           user_dt.items.company_name === "KLT Machinery and Plant Hire"
  //         ) {
  //           setIframeSource(klt_src);
  //         } else if (
  //           user_dt.items.company_name === "Red Top Asset Management"
  //         ) {
  //           setIframeSource(red_t_src);
  //         } else {
  //           setIframeSource(null);
  //         }
  //       }
  //     }
  //   }
  // }, [user_dt]);

  return (
    <div className="flex">
      <SideBar />
      <main className="w-full min-w-[70rem] overflow-x-auto  py-10">
        {/* <div className="col-sm-10 col-lg-11 col-xs-10 col-md-10 min-hgt-574 row border-box d-flex width-100 main-div mx-auto ml-0 mt-20 pl-0 pr-0"> */}
        {/* <iframe
            title="YM Hire - Contractors Application Report snowflakeV3"
            width="1140"
            height="541.25"
            src={iframeSource}
            // src="https://app.powerbi.com/reportEmbed?reportId=3630a01e-264a-4315-8dba-4fab9592e587&autoAuth=true&ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc"
            frameborder="0"
            allowFullScreen="true"
          ></iframe> */}


        {userRole === "CFO" || userRole === "Approver" ? (
          // <iframe
          //   title="YMH CFO Report"
          //   width="100%"
          //   height="570.25"
          //   src="https://app.powerbi.com/reportEmbed?reportId=b35978aa-0857-4a27-96ce-4e3c5bc1801e&autoAuth=true&ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc"
          //   frameborder="0"
          //   allowFullScreen="true"
          // ></iframe>

          <div className="flex justify-center items-center h-screen">
            <button onClick={() => window.open("https://app.powerbi.com/reportEmbed?reportId=b35978aa-0857-4a27-96ce-4e3c5bc1801e&autoAuth=true&ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc", "_blank")} className="report__btn">
              View Report
            </button>
          </div>


        ) : user_dt ? (
          user_dt.items !== undefined ? (
            user_dt.items.company_name === "Red Top Asset Management" ? (
              // <iframe
              //   title="YM Hire - REDTOP report"
              //   width="1140"
              //   height="541.25"
              //   src="https://app.powerbi.com/reportEmbed?reportId=edcf07d0-9ba6-4a6c-9a8a-24a9f60b0192&autoAuth=true&ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc"
              //   frameborder="0"
              //   allowFullScreen="true"
              // ></iframe>

              <div className="flex justify-center items-center h-screen">
                <button onClick={() => window.open("https://app.powerbi.com/reportEmbed?reportId=edcf07d0-9ba6-4a6c-9a8a-24a9f60b0192&autoAuth=true&ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc", "_blank")} className="report__btn">
                  View Report
                </button>
              </div>

            ) : user_dt.items.company_name === "Quartz Plant Hire" ? (
              // <iframe
              //   title="YM Hire - QUARTZ report"
              //   width="1140"
              //   height="541.25"
              //   src="https://app.powerbi.com/reportEmbed?reportId=c88542a5-dcf4-4f15-b229-8171f6c05587&autoAuth=true&ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc"
              //   frameborder="0"
              //   allowFullScreen="true"
              // ></iframe>

              <div className="flex justify-center items-center h-screen">
                <button onClick={() => window.open("https://app.powerbi.com/reportEmbed?reportId=c88542a5-dcf4-4f15-b229-8171f6c05587&autoAuth=true&ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc", "_blank")} className="report__btn">
                  View Report
                </button>
              </div>
            ) : user_dt.items.company_name ===
              "KLT Machinery and Plant Hire" ? (
              // <iframe
              //   title="YM Hire - KLT report"
              //   width="1140"
              //   height="541.25"
              //   src="https://app.powerbi.com/reportEmbed?reportId=012a8271-3cde-4962-a399-327d5c80c9d8&autoAuth=true&ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc"
              //   frameborder="0"
              //   allowFullScreen="true"
              // ></iframe>

              <div className="flex justify-center items-center h-screen">
                <button onClick={() => window.open("https://app.powerbi.com/reportEmbed?reportId=012a8271-3cde-4962-a399-327d5c80c9d8&autoAuth=true&ctid=c6f6dd74-a042-4f10-8258-6b311f5d8ccc", "_blank")} className="report__btn">
                  View Report
                </button>
              </div>
            ) : null
          ) : (
            <div className="report-loader">
              <span className="fa fa-spinner fa-spin"></span>
            </div>
          )
        ) : (
          <div className="report-loader">
            <span className="fa fa-spinner fa-spin"></span>
          </div>
        )}
        {/* </div> */}
      </main>
    </div>
  );
}

export default Reports;
