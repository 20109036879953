/**
 * Timesheet Add -
 */
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import SideBar from "./SideBar";
import { timesheetActions } from "../store/actions/timeSheet.actions";
import { Calendar } from "react-multi-date-picker";
import SignaturePad from "react-signature-canvas";
import { userActions } from "../store/actions/user.actions";
import Select from "react-select";
import HelpIcon from "../assets/icons/helpIcon";

const rateList = [
  {
    key: "Wet",
    value: "Wet",
  },
  {
    key: "Dry",
    value: "Dry",
  },
];
const periodList = [
  { key: "Day", value: "Day" },
  { key: "Night", value: "Night" },
  { key: "24 Hours", value: "24 hours" },
];
export default function AddNewTimesheet() {
  const [timesheetSaved, setTimesheetSaved] = useState(false);
  const user_dt = useSelector((state) => state.users);
  let newDate = new Date();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const dispatch = useDispatch();
  const machine_sub_list = useSelector(
    (state) => state.OptionsList.machine_sub_list
  );
  const site_name_list = useSelector(
    (state) => state.OptionsList.site_name_list
  );

  const machine_list = useSelector((state) => state.OptionsList.machine_list);
  const po_list = useSelector((state) => state.OptionsList.po_list);
  const vehicle_code_list = useSelector(
    (state) => state.OptionsList.vehicle_code_list
  );
  const timesheet_id = useSelector((state) => state.timesheet.data);
  const [timeSheetData, setTimesheetData] = useState([]);
  const [machine, setMachine] = useState(null);
  const [siteName, setSiteName] = useState(null);

  const [machineSubType, setMachineSubType] = useState(null);
  const [vehicleCode, setVehicleCode] = useState(null);
  // const company = "Red Top Asset Management";
  const company =
    user_dt.items !== undefined ? user_dt.items.company_name : null;
  const [orderNumber, setOrderNumber] = useState(null);
  const [costCenter, setCostCenter] = useState(null);
  const [managerName, setManagerName] = useState(null);
  const [sheetStatus, setSheetStatus] = useState(null);
  const [foreman, setForeman] = useState(null);
  const [po, setPo] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [totalHours, setTotalHours] = useState(null);
  const [daysPerMonth, setDaysPerMonth] = useState(null);
  const [perDay, setPerDay] = useState(null);
  const [numericMonth, setNumericMonth] = useState(month);
  const [numericyear, setNumericyear] = useState(year);
  const [hrsVal, setHrsVal] = useState(null);
  const [hrsDate, setHrsDate] = useState(null);
  const [isSubmittable, setIsSubmittable] = useState(true);
  const [firstDate, setFirstDate] = useState(`${year}/${month}/1`);
  const [validationMessages, setValidationMessages] = useState([]);
  const [alertMsg, setAlertMsg] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isMonthChanged, setIsMonthChanged] = useState(false);
  const [onUpdateVal, setOnUpdate] = useState(false);
  const [sheetNote, setSheetNote] = useState(null);
  const handleNotesChange = (note) => {
    const updatedNote = note.replace(/\s+/g, " ");
    setSheetNote(updatedNote);
  };
  const timesheet_loading = useSelector(
    (state) => state.timesheet.timesheet_loading
  );
  const alert_message = useSelector((state) => state.timesheet.message);
  const alert_type = useSelector((state) => state.timesheet.alert_type);
  const submit_alert_message = useSelector(
    (state) => state.timesheetSubmit.message
  );
  const timesheet_submit_loading = useSelector(
    (state) => state.timesheetSubmit.timesheet_submit_loading
  );
  const submit_alert_type = useSelector(
    (state) => state.timesheetSubmit.alert_type
  );

  const handleTimeSheetChange = (name, value, date) => {
    if (!value) {
      value = null;
    }
    let updatedList = timeSheetData.map((item) => {
      if (item.date === date) {
        return { ...item, [name]: value };
      }
      return item;
    });
    setTimesheetData(updatedList);
    const total = updatedList.reduce(function (cnt, o) {
      return cnt + (o.hours !== null ? parseFloat(o.hours) : 0);
    }, 0);

    if (
      total === null ||
      total === undefined ||
      total === 0 ||
      total.toString() === "NaN"
    ) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }

    let selected_days = updatedList.filter(
      (item) => item.hours !== null && item.hours !== "None"
    ).length;

    setTotalHours(total);
    setPerDay(parseFloat(total / selected_days).toFixed(2));
  };

  function getDaysInMonth(year, month) {
    setTimesheetData([]);
    let dateArry = [];
    let len = 0;
    len = new Date(year, month, 0).getDate();

    setDaysPerMonth(len);
    for (let i = 1; i <= len; i++) {
      let monthLong = new Date(
        String(year + "/" + month + "/" + i)
      ).toLocaleDateString("en-us", { month: "long" });
      let dayLong = new Date(
        String(year + "/" + month + "/" + i)
      ).toLocaleDateString("en-us", { weekday: "long" });
      dateArry.push({
        day: dayLong,
        date:
          i +
          "-" +
          monthLong.toString().substring(0, 3) +
          "-" +
          year.toString(),
        hours: null,
        rate: "Wet",
        period: "Day",
      });
    }
    setTimesheetData(dateArry);
  }
  const handlehourschange = (e, date) => {
    let val = e.target.value;
    // if (!e.target.value) {
    //   val = 0;
    // }
    if (e.target.value > 24) {
      val = 24;
    }
    if (e.target.value < 0) {
      val = 0;
    }

    if (!val || val.match(/^\d{1,}(\.\d{0,1})?$/)) {
      setHrsVal(val);
    }
    setTimeout(() => {
      handleTimeSheetChange(e.target.name, val, date);
    }, 100);

    setHrsDate(date);
  };
  const handleinvoiceChange = (target) => {
    setInvoice(target);
    // if (target === null || target === undefined || target.length === 0) {
    //   setIsSubmittable(false);
    // } else {
    //   setIsSubmittable(true);
    // }
  };
  const handleordernumberChange = (target) => {
    setOrderNumber(target);
    // if (target === null || target === undefined || target.length === 0) {
    //   setIsSubmittable(false);
    // } else {
    //   setIsSubmittable(true);
    // }
  };
  const handlecostcenterChange = (target) => {
    setCostCenter(target);
    if (target === null || target === undefined || target.length === 0) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };
  const handleInputPOChange = (val) => {
    val = val.value;
    setPo(val);
    if (
      val === null ||
      val === "Select" ||
      val === undefined ||
      val.length === 0
    ) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };
  const handleInputMachineChange = (val) => {
    setMachine(val);
    if (
      val === null ||
      val === undefined ||
      val.length === 0 ||
      val === "Select"
    ) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };

  const handleInputMachineSubTypeChange = (val) => {
    setMachineSubType(val);
    if (
      val === null ||
      val === undefined ||
      val.length === 0 ||
      val === "Select"
    ) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };

  const handleInputVehicleCodeChange = (val, e) => {
    setVehicleCode(val);
    setMachineSubType(e.Machine_sub_type);
    setMachine(e.Machine_type);
    if (
      val === null ||
      val === undefined ||
      val.length === 0 ||
      val === "Select"
    ) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };
  const handleSiteNameChange = (val) => {
    setSiteName(val);
    if (
      val === null ||
      val === undefined ||
      val.length === 0 ||
      val === "Select"
    ) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };
  function validateInput(event) {
    var keyCode = event.keyCode || event.which;
    var keyValue = String.fromCharCode(keyCode);

    // Check if the input is a number
    if (!isNaN(parseFloat(keyValue)) && isFinite(keyValue)) {
      event.preventDefault(); // Prevent the input
      return false;
    }
  }

  const onInputChange = (e) => {
    const { value } = e.target;

    const re = /^[A-Za-z]+$/;

    if (value === "" || re.test(value)) {
      setForeman(value);
      if (value === null || value === undefined || value.length === 0) {
        setIsSubmittable(false);
      } else {
        setIsSubmittable(true);
      }
    } else if (re.test(value) === false) {
      const filteredValue = value.replace(/[0-9]/g, "");
      setForeman(filteredValue);
    }
  };
  const handlemanagernameChange = (e) => {
    const { value } = e.target;
    const re = /^[A-Za-z]+$/;

    if (value === "" || re.test(value)) {
      setManagerName(value);
      if (value === null || value === undefined || value.length === 0) {
        setIsSubmittable(false);
      } else {
        setIsSubmittable(true);
      }
    } else if (re.test(value) === false) {
      const filteredValue = value.replace(/[0-9]/g, "");
      setManagerName(filteredValue);
      setIsSubmittable(true);
    }
    // setManagerName(target);
    // if (target === null || target === undefined || target.length === 0) {
    //   setIsSubmittable(false);
    // } else {
    //   setIsSubmittable(true);
    // }
  };
  const handleSigantureChange = (target) => {
    setSignature(target);
    if (target === null || target === undefined || target.length === 0) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };

  const submit = (status) => {
    setSheetStatus(status);
    setShowAlertMsg(null);
    let dataDict = {
      machine: machine,
      site_name: siteName,
      machineSubType: machineSubType,
      vehicleCode: vehicleCode,
      company: company,
      foreman: foreman,
      po: po,
      cost_center: costCenter,
      order_number: orderNumber,
      // viewer: selectedViewer,
      manager_name: managerName,
      // signature: signature,
      signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
      invoice: invoice,
      status: status,
      total: totalHours,
      total_hours: totalHours,
      per_day: perDay,
      days_per_month: daysPerMonth,
      days_data: timeSheetData,
      year: numericyear,
      month: numericMonth,
      month_year: firstDate,
      notes: sheetNote,
      pdf_base64: fileData,
    };

    if (timesheet_id && status === "Complete") {
      dataDict["timesheet_id"] = timesheet_id;
    }

    let messages = [];

    if (!machine || machine === "Select") {
      messages.push("Machine is required");
    }

    if (!machineSubType || machineSubType === "Select") {
      messages.push("Machine Subtype required");
    }

    if (!vehicleCode || vehicleCode === "Select") {
      messages.push("Vehicle Code is required");
    }
    if (!siteName || siteName === "Select") {
      messages.push("Site Name is required");
    }

    // if (!costCenter) {
    //   messages.push("Cost Center is required");
    // }
    // if (!orderNumber) {
    //   messages.push("Reference Number is required");
    // }
    if (!managerName) {
      messages.push("Name is required");
    }
    if (!company) {
      messages.push("Company is required");
    }
    if (!foreman) {
      messages.push("Foreman is required");
    }
    // if (!invoice) {
    //   messages.push("Invoice is required");
    // }
    if (timesheet_id && status === "Complete") {
      if (!po || po === "Select") {
        messages.push("P.O is required");
      }
    }
    if (timeSheetData.length > 0) {
      let count = 0;
      timeSheetData.map((item) => {
        if (item.hours !== null) {
          count += 1;
          return count;
        }
      });
      if (count === 0) {
        messages.push("Hours is required");
      }
    }

    if (messages.length > 0) {
      setAlertMsg(false);
      setIsMonthChanged(false);
      const error_violation = document.getElementById("msg_div");
      window.scrollTo({
        top: error_violation.offsetTop,
        behavior: "smooth",
      });
      setValidationMessages(messages);
    } else {
      setIsMonthChanged(false);
      setAlertMsg(true);
      setValidationMessages([]);

      if (timesheet_id && status === "Complete") {
        dispatch(timesheetActions.UpdateTimesheetStatus(dataDict));
        // alertMsg(false);
        setIsAlertVisible(true);
        setOnUpdate(true);
        // setTimeout(() => {
        //   setIsAlertVisible(false);
        // }, 15000);
      } else {
        setTimesheetSaved(true);
        dispatch(timesheetActions.submitTimesheetData(dataDict));
      }
    }
  };
  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString("en-US", {
      month: "long",
    });
  }
  const handleMonthChange = (event) => {
    setPerDay(null);
    setTotalHours(null);
    setAlertMsg(false);
    setIsMonthChanged(true);
    setTimesheetData([]);
    setFirstDate(event);
    var selectedYear = new Date(String(event)).toLocaleDateString("en-us", {
      year: "numeric",
    });
    var selectedMonth = new Date(String(event)).toLocaleDateString("en-us", {
      month: "numeric",
    });
    getDaysInMonth(selectedYear, selectedMonth);
    setNumericyear(selectedYear);
    setNumericMonth(selectedMonth);
  };
  const disableFutureDates = (date) => {
    const currentDate = new Date();
    return date.isAfter(currentDate); // Disable only future dates
  };
  const [signature, setSignature] = useState(null);
  const savesignature = () => {
    setSignature(sigCanvas.current.toDataURL());
  };
  const sigCanvas = useRef({});

  const handleSignature = () => {
    savesignature();
    setIsSubmittable(true);
  };
  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignature(null);
    setValidationMessages([]);
  };
  const [showAlertMsg, setShowAlertMsg] = useState(null);
  const handleMouseOver = (msg) => {
    setShowAlertMsg(msg);

    setTimeout(() => {
      setShowAlertMsg(null);
    }, 2000);
  };
  const [fileData, setFileData] = useState(null);
  const handleFileChange = (event) => {
    setValidationMessages([]);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFileData(reader.result);
      };
    }
  };

  useEffect(() => {
    if (localStorage.getItem("user_role") !== "Contractor") {
      window.location.assign("/");
    }
    dispatch(userActions.getUserData());
    dispatch(timesheetActions.getOptionsList());
    getDaysInMonth(year, month);
  }, []);
  return (
    <div className="flex">
      <SideBar />
      <main className="w-full min-w-[50rem] overflow-x-auto bg-gray-100 py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center">
              <div className="space-y-4 sm:flex-auto">
                <h1 className="text-3xl font-semibold leading-6 text-gray-900">
                  Add Timesheets
                </h1>
                <p className="min-width-350 mt-2 text-sm text-gray-500">
                  You can add new timesheets here.
                </p>
              </div>
            </div>
            <div className="mt-8 rounded-lg bg-white p-6">
              <div className="space-y-6">
                <div>
                  <div className="pb-4">
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">
                      <div className="d-flex justify-content-center " 
                       style={{ display: 'flex', justifyContent: 'center' }} >
                        <Calendar
                          onMonthChange={(MonthObject) => {
                            handleMonthChange(MonthObject.format());
                          }}
                          maxDate={new Date()}
                        />
                      </div>
                    </h2>
                  </div>

                  <hr className="-mx-6" />

                  <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-3">
                    <div>
                      <label
                        htmlFor="machine"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Machine
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="machine"
                          id="machine"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="Machine"
                          value={machine}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="MachineSubType"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Machine Subtype
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="MachineSubType"
                          id="MachineSubType"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="Machine Subtype"
                          value={machineSubType}
                          disabled={true}
                        ></input>
                        {/* <select
                          disabled={
                            machine && machine !== "Select" ? false : true
                          }
                          onChange={(event) =>
                            handleInputMachineSubTypeChange(event.target.value)
                          }
                          name="MachineSubType"
                          id="MachineSubType"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                        >
                          {machine_sub_list
                            ? machine_sub_list.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.value}
                                </option>
                              ))
                            : []}
                        </select> */}
                      </div>
                    </div>

                    {/* <div>
                      <label
                        htmlFor="Company"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Company
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="Company"
                          id="Company"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          value={company}
                          disabled
                        />
                      </div>
                    </div> */}

                    <div>
                      <label
                        htmlFor="site-name"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Foreman
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="Foreman"
                          id="Foreman"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="Foreman"
                          value={foreman}
                          onChange={onInputChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="purchase-order-number"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Purchase Order Number
                      </label>
                      <div className="mt-2">
                        <Select
                          disabled={po_list ? false : true}
                          onChange={(event) => handleInputPOChange(event)}
                          options={po_list ? po_list : []}
                          defaultValue={
                            po
                              ? { value: po, label: po }
                              : { value: "Select", label: "Select" }
                          }
                          name="purchase-order-number"
                          id="purchase-order-number"
                          className="select2 height-40 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                        />
                        {/* {po_list
                            ? po_list.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.value}
                                </option>
                              ))
                            : []} */}
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="invoice"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Invoice Number
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="invoice"
                          id="invoice"
                          placeholder="Invoice Number"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          onChange={(event) =>
                            handleinvoiceChange(event.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="order_number"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Reference Number
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="order_number"
                          id="order_number"
                          placeholder="Reference Number"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          onChange={(event) =>
                            handleordernumberChange(event.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="vehicleCode"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Vehicle Code
                      </label>
                      <div className="mt-2">
                        <Select
                          disabled={vehicle_code_list ? false : true}
                          onChange={(event) =>
                            handleInputVehicleCodeChange(event.value, event)
                          }
                          options={vehicle_code_list ? vehicle_code_list : []}
                          defaultValue={
                            vehicleCode
                              ? { value: vehicleCode, label: vehicleCode }
                              : { value: "Select", label: "Select" }
                          }
                          name="vehicleCode"
                          id="vehicleCode"
                          className="select2 height-40 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                        />
                        {/* {vehicle_code_list
                            ? vehicle_code_list.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.value}
                                </option>
                              ))
                            : []}
                        </select> */}
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="vehicleCode"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Site Name
                      </label>
                      <div className="mt-2">
                        <Select
                          disabled={site_name_list ? false : true}
                          onChange={(event) =>
                            handleSiteNameChange(event.value)
                          }
                          options={site_name_list ? site_name_list : []}
                          defaultValue={
                            siteName
                              ? { value: siteName, label: siteName }
                              : { value: "Select", label: "Select" }
                          }
                          // defaultValue={site_name_list &&  site_name_list[0]}
                          name="SiteName"
                          id="SiteName"
                          className="select2 height-40 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                        />

                        {/* {site_name_list
                            ? site_name_list.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.value}
                                </option>
                              ))
                            : []}
                        </select> */}
                      </div>
                    </div>

                    <div></div>

                    <div className="col-span-3 my-6 flow-root rounded-lg border border-gray-200 bg-white">
                      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="relative">
                            <table className="min-w-full table-fixed divide-y divide-gray-200">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="border-r border-gray-200 py-3.5 pl-5 pr-3 text-left text-sm font-normal text-gray-400"
                                  >
                                    <div className="flex items-center space-x-1">
                                      <span>Day</span>
                                      <button type="button">
                                        <ChevronDownIcon className="hw-2" />
                                      </button>
                                    </div>
                                  </th>
                                  <th
                                    scope="col"
                                    className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                                  >
                                    <div className="flex items-center space-x-1">
                                      <span>Date</span>
                                      <button type="button">
                                        <ChevronDownIcon className="hw-2" />
                                      </button>
                                    </div>
                                  </th>
                                  <th
                                    scope="col"
                                    className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                                  >
                                    <div className="flex items-center space-x-1">
                                      <span>Hours</span>
                                      <button type="button">
                                        <ChevronDownIcon className="hw-2" />
                                      </button>
                                    </div>
                                  </th>
                                  <th
                                    scope="col"
                                    className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                                  >
                                    <div className="flex items-center space-x-1">
                                      <span>Rate</span>
                                      <button type="button">
                                        <ChevronDownIcon className="hw-2" />
                                      </button>
                                    </div>
                                  </th>
                                  <th
                                    scope="col"
                                    className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                                  >
                                    <div className="flex items-center space-x-1">
                                      <span>Period</span>
                                      <button type="button">
                                        <ChevronDownIcon className="hw-2" />
                                      </button>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {timeSheetData && timeSheetData.length > 0
                                  ? timeSheetData.map((item, index) => (
                                      <tr key={index}>
                                        <td className="whitespace-nowrap py-4 pl-5 pr-3 text-sm text-gray-500">
                                          {item.day}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {item.date}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <div className="max-w-[10rem]">
                                            <input
                                              type="number"
                                              name="hours"
                                              id="hours"
                                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                                              min={0}
                                              placeholder="0"
                                              key={item.hours}
                                              value={
                                                item.date === hrsDate
                                                  ? hrsVal
                                                  : item.hours
                                              }
                                              onChange={(event) =>
                                                handlehourschange(
                                                  event,
                                                  item.date
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <select
                                           style={{ display: 'flex', justifyContent: 'center' }}
                                            className="form-control table-input-box d-flex mx-auto css-tj5bde-Svg"
                                            name="rate"
                                            value={timeSheetData.rate}
                                            onChange={(event) =>
                                              handleTimeSheetChange(
                                                event.target.name,
                                                event.target.value,
                                                item.date
                                              )
                                            }
                                          >
                                            {rateList.map((data, key) => (
                                              <option
                                                key={key}
                                                value={data.key}
                                              >
                                                {data.value}
                                              </option>
                                            ))}
                                          </select>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <select
                                           style={{ display: 'flex', justifyContent: 'center' }}
                                            className="form-control table-input-box d-flex mx-auto css-tj5bde-Svg"
                                            name="period"
                                            value={timeSheetData.period}
                                            onChange={(event) =>
                                              handleTimeSheetChange(
                                                event.target.name,
                                                event.target.value,
                                                item.date
                                              )
                                            }
                                          >
                                            {periodList.map((data, key) => (
                                              <option
                                                key={key}
                                                value={data.value}
                                              >
                                                {data.value}
                                              </option>
                                            ))}
                                            ``
                                          </select>
                                        </td>
                                      </tr>
                                    ))
                                  : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="total-hours"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Total Hours
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="total-hours"
                          id="total-hours"
                          disabled={true}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          value={totalHours}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="days-per-month"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Days Per Month
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="days-per-month"
                          id="days-per-month"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          disabled={true}
                          value={daysPerMonth}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="per-day"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Average Hours Per Day
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="per-day"
                          id="per-day"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          disabled={true}
                          value={perDay}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="total"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Total
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="total"
                          id="total"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          // placeholder="14"
                          disabled={true}
                          value={totalHours}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Name
                      </label>
                      <div className="mt-2">
                        <input
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          type="text"
                          id="manager_name"
                          name="manager_name"
                          value={managerName}
                          onChange={handlemanagernameChange}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Upload File
                      </label>
                      <div className=" mt-2">
                        <button
                          title="Important Notice: Please Upload Only PDF Files"
                          type="button"
                          className="upload-btn flex w-full justify-center rounded-md bg-[#FCAF17] p-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FCAF17]"
                        >
                          <label className="custom-file-upload">
                            <input
                              type="file"
                              onChange={handleFileChange}
                              accept="application/pdf"
                              style={{ display: "none" }}
                            />
                            {fileData
                              ? `File Selected`
                              : `Click here to select a file`}
                          </label>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 grid grid-cols-12 gap-4">
                    <div className="col-span-4">
                      <label
                        htmlFor="signature"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Signature
                      </label>
                      <div className="mt-2">
                        <SignaturePad
                          onChange={handleSignature}
                          ref={sigCanvas}
                          canvasProps={{
                            className: "signatureCanvasNew",
                          }}
                        />
                        <div className="clr-sign">
                          <span
                            className="clear-btn"
                            type="clearSignature"
                            id="clearSignature"
                            onClick={clearSignature}
                          >
                            Clear
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-8">
                      <label
                        htmlFor="Note"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Note
                      </label>
                      <div className="mt-2">
                        <textarea
                          rows={4}
                          name="Notes"
                          id="Notes"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="Type your note here..."
                          onChange={(e) => handleNotesChange(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  className=" wdth-173 focus-visible:outline-bg-[#FCAF17] mt-6 inline-flex justify-center rounded-md bg-[#FCAF17] px-7 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  onClick={() => submit("In-progress")}
                  disabled={timesheet_loading ? true : false}
                >
                  {timesheet_loading && (
                    <span className="fa fa-spinner fa-spin"></span>
                  )}{" "}
                  Save Changes
                </button>
                <div
                  onMouseOver={() =>
                    timesheet_id && isSubmittable && timesheetSaved
                      ? null
                      : handleMouseOver("Please click on save changes first")
                  }
                  title={
                    timesheet_id && isSubmittable && timesheetSaved
                      ? null
                      : "Please click on save changes first"
                  }
                >
                  <button
                    onClick={() => submit("Complete")}
                    disabled={
                      timesheet_id && isSubmittable && timesheetSaved
                        ? false
                        : true
                    }
                    type="button"
                    className=" wdth-173 focus-visible:outline-bg-[#FCAF17] mt-6 inline-flex justify-center rounded-md bg-[#FCAF17] px-7 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  >
                    {timesheet_submit_loading && (
                      <span className="fa fa-spinner fa-spin"></span>
                    )}{" "}
                    Submit Timesheet
                  </button>
                </div>
              </div>
              {showAlertMsg && (
                <div className="col-12 mt-3">
                  <p className="fail-msg flt-right">{showAlertMsg}</p>
                </div>
              )}
              <div
                className="clr-red d-flex justify-content-center mt-20"
                style={{justifyContent:'center', display:'flex'}}
                id="msg_div"
              >
                <div id="msg_div">
                  <ul>
                    {validationMessages.map((vm) => (
                      <li key={vm}>{vm}</li>
                    ))}
                  </ul>
                </div>
              </div>
              {alertMsg === true &&
                alert_type === "TIMESHEET_SUCCESS" &&
                onUpdateVal === false && (
                  <div>
                    <p className="success-msg ">Timesheet Saved Successfully</p>
                  </div>
                )}
              {isAlertVisible === true &&
                submit_alert_type &&
                submit_alert_type === "UPDATE_TIMESHEET_STATUS_SUCCESS" && (
                  <div>
                    <p className="success-msg ">
                      Timesheet Submitted Successfully
                    </p>
                  </div>
                )}
              {alert_type === "TIMESHEET_FAILURE" && alert_message && (
                <div>
                  <p className="fail-msg">{alert_message}</p>
                </div>
              )}
              {alert_type === "UPDATE_TIMESHEET_STATUS_FAILURE" &&
                submit_alert_message && (
                  <div>
                    <p className="fail-msg">{submit_alert_message``}</p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
