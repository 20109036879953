import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { machineActions } from "../../store/actions/machine.action";
import { useDispatch } from "react-redux";


export function MachineSubType({machineTypes,updatePageNo}) {
  const dispatch = useDispatch();
  const machine_types = machineTypes?machineTypes:[]

  const handleOptionClick = (machine_type) => {
    updatePageNo(1);
    localStorage.setItem(
      "machineSubtype",
      machine_type
  );
    dispatch(
      machineActions.getMachinelist(
        localStorage.getItem("machineType")
        ? localStorage.getItem("machineType")
        : null,
        machine_type,
        localStorage.getItem("search")
        ? localStorage.getItem("search")
        : null,
        localStorage.getItem("machComapny")
        ? localStorage.getItem("machComapny")
        : null,

          1
      )
  );
  }

  const [selected, setSelected] = useState(null);
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-2 min-w-[10rem]">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#FCAF17] sm:text-sm sm:leading-6" style={{borderRadius:'6px'}}>
              <span className="flex items-center space-x-1.5 truncate">
                { !localStorage.getItem("machineSubtype") && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="none"
                  >
                    <path
                      fill="#657488"
                      d="m10 18.333-7.5-4.291V5.729L10 1.667l7.5 4.062v8.313L10 18.333ZM7.625 7.958c.32-.32.684-.58 1.094-.781a2.87 2.87 0 0 1 2.562 0c.41.201.775.462 1.094.781l3.188-1.875L10 3.125 4.437 6.083l3.188 1.875Zm1.75 8.584v-3.48a3.483 3.483 0 0 1-1.792-1.124A2.901 2.901 0 0 1 6.875 10c0-.153.01-.31.031-.469a2.2 2.2 0 0 1 .115-.469L3.75 7.125v6.188l5.625 3.229ZM10 11.875c.528 0 .972-.18 1.333-.542.361-.36.542-.805.542-1.333 0-.528-.18-.972-.542-1.333A1.812 1.812 0 0 0 10 8.125c-.528 0-.972.18-1.333.542A1.812 1.812 0 0 0 8.125 10c0 .528.18.972.542 1.333.36.361.805.542 1.333.542Zm.625 4.667 5.625-3.23V7.125l-3.27 1.937c.055.153.093.31.114.47.02.159.031.315.031.468 0 .736-.236 1.382-.708 1.938-.473.555-1.07.93-1.792 1.124v3.48Z"
                    />
                  </svg>
                )}
                <span className="fs-14 fs-text">{localStorage.getItem("machineSubtype")
                        ? localStorage.getItem("machineSubtype")
                         : "Machine Subtype"}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 f-icon">
                <ChevronDownIcon
                  className="hw-2 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="fs-14 absolute z-10 mt-1 max-h-dp w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {machine_types && machine_types.length>0 ? machine_types.map((item) => (
                  <Listbox.Option
                    title={item.label}
                    key={item.label}
                    className={({ active }) =>
                      clsx(
                        active ? "bg-gray-50" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item.value}
                    onClick={() => handleOptionClick(item.value)}
                  >
                    {({ selected }) => (
                      <div className="flex items-center space-x-2">
                        <span
                          className={clsx(
                            selected && localStorage.getItem("machineSubtype") ? "bg-[#FCAF17]" : "bg-gray-200",
                            "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                          )}
                        />
                        <span
                          className={clsx(
                            selected && localStorage.getItem("machineSubtype") ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item.value}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                )):null}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
