import * as config from "../config/config";
import { authHeader } from "./http.header";

export const AnomalieService = {
  getAnomalielist,
  updateAnomaliesStatus,
};

function getAnomalielist(
  siteType,
  docType,
  statusType,
  start_date,
  end_date,
  company,
  page,
  anom_search
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/anomalies/anomalies-data/?siteType=${siteType}&documenttype=${docType}&statusType=${statusType}&end_date=${end_date}&company=${company}&start_date=${start_date}&page_no=${page}&anom_search=${anom_search}`,
    requestOptions
  ).then(handleResponse);
}

function updateAnomaliesStatus(anom_data, a_id) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({ anomalies_status: anom_data }),
  };

  return fetch(
    `${config.apiUrl}/anomalies/update-anomalies/${a_id}/`,

    requestOptions
  )
    .then(handleResponse)
    .then((anom_res) => {
      return anom_res;
    });
}
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (response.status === 200) {
    } else {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
