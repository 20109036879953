import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { timesheetActions } from "../store/actions/timeSheet.actions";
import SignaturePad from "react-signature-canvas";
import { Calendar, DateObject } from "react-multi-date-picker";
import { MDBContainer } from "mdbreact";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import NavBar from "./NavBar";
import { history } from "../helpers/history";

const TimeSheetPrefilled = () => {
    // let tpc = window.location.search.split("tpc=")[1];
    const selectedsheetid = window.location.href.split("id=")[1];
    const sheetData = useSelector((state) => state.SheetDataById.data);
    const daysData = useSelector((state) => state.SheetDataById.days_data);

    const [selectedViewer, setSelectedViewer] = useState(null);
    const [isSubmittable, setIsSubmittable] = useState(true);

    const rateList = [
        {
            key: "Dry",
            value: "Dry",
        },
        {
            key: "Wet",
            value: "Wet",
        },
    ];

    const periodList = [
        { key: "Day", value: "Day" },
        { key: "Night", value: "Night" },
        { key: "24 Hours", value: "24 hours" },
    ];

    const [hrsVal, setHrsVal] = useState(null);
    const [hrsDate, setHrsDate] = useState(null);

    const handlehourschange = (e, date) => {
        let val = e.target.value;

        if (!val || val.match(/^\d{1,}(\.\d{0,1})?$/)) {
            setHrsVal(val);
        }
        setTimeout(() => {}, 1000);

        setHrsDate(date);
    };

    const [isAlertVisible, setIsAlertVisible] = useState(false);

    const [signature, setSignature] = useState(null);

    const clearSignature = () => {
        sigCanvas.current.clear();
        setSignature(null);
        setValidationMessages([]);

        setIsSubmittable(false);
    };

    const timesheet_loading = useSelector(
        (state) => state.timesheet.timesheet_loading
    );
    let newDate = new Date();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    const alert_type = useSelector((state) => state.timesheet.alert_type);
    const [alertMsg, setAlertMsg] = useState(false);
    const sigCanvas = useRef({});
    const dispatch = useDispatch();
    const [validationMessages, setValidationMessages] = useState([]);
    const [timeSheetData, setTimesheetData] = useState([]);

    const [foreman, setForeman] = useState(null);

    const [daysPerMonth, setDaysPerMonth] = useState(null);

    function getDaysInMonth(year, month) {
        setTimesheetData([]);
        let dateArry = [];
        let len = 0;
        len = new Date(year, month, 0).getDate();

        setDaysPerMonth(len);
        for (let i = 1; i <= len; i++) {
            let monthLong = new Date(
                String(year + "/" + month + "/" + i)
            ).toLocaleDateString("en-us", { month: "long" });
            let dayLong = new Date(
                String(year + "/" + month + "/" + i)
            ).toLocaleDateString("en-us", { weekday: "long" });
            dateArry.push({
                day: dayLong,
                date:
                    i +
                    "-" +
                    monthLong.toString().substring(0, 3) +
                    "-" +
                    year.toString(),
                hours: null,
                rate: "Dry",
                period: "Day",
            });
        }
        setTimesheetData(dateArry);
    }

    const onInputChange = (e) => {
        const { value } = e.target;

        const re = /^[A-Za-z]+$/;
        if (value === "" || re.test(value)) {
            setForeman(value);
            if (value === null || value === undefined || value.length === 0) {
                setIsSubmittable(false);
            } else {
                setIsSubmittable(true);
            }
        }
    };
    const [sheetMessqage, setSheetMessage] = useState(null);

    const submit = (status) => {
        let auditable = false;
        let approved = true;
        if (status === "Audit") {
            auditable = true;
            approved = false;
        }

        if (status === "Approved") {
            auditable = false;
            approved = true;
        }

        let dataDict = {
            sheet_id: sheetData["id"],
            is_auditable: auditable,
            is_approved: approved,
            messages: sheetMessqage,
        };

        let messages = [];

        if (!sheetMessqage) {
            messages.push("Message is required");
        }

        if (messages.length > 0) {
            const error_violation = document.getElementById("msg_div");
            window.scrollTo({
                top: error_violation.offsetTop,
                behavior: "smooth",
            });
            setValidationMessages(messages);
        } else {
            setAlertMsg(true);
            setValidationMessages([]);
            dispatch(timesheetActions.timesheetApprovedOrAudit(dataDict));
        }
    };

    useEffect(() => {
        getDaysInMonth(year, month);
        if (selectedsheetid) {
            dispatch(timesheetActions.getSheetById(selectedsheetid));
        }
    }, []);

    return (
        <>
            <NavBar />
            <main className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row m-top-40">
                <div className="col-sm-10 col-lg-10 col-xs-10 col-md-10 row mr-0 ml-0 pr-0 pl-0  mt-20 border-box d-flex mx-auto width-70">
                    <MDBContainer>
                        <div className="text-center mt-4 ">
                            <h2 className="text-center clr-gray">
                                <p className="p-0 m-0">Enter Timesheet</p>
                            </h2>

                            <div className="d-flex justify-content-center">
                                {sheetData && (
                                    <Calendar
                                        currentDate={
                                            new DateObject({
                                                year: sheetData["year"],
                                                month: sheetData["month"],
                                            })
                                        }
                                        disabled
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row p-0 m-0 d-flex justify-content-center clr-gray">
                            <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 p-0 m-0 mt-2 mb-2 ">
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <label htmlFor="machine">Machine</label>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <input
                                        className="input-style wdth-240"
                                        type="text"
                                        list="machines"
                                        disabled
                                        value={
                                            sheetData && sheetData["machine"]
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 p-0 m-0 mt-2 mb-2 ">
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <label htmlFor="company">Company</label>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <input
                                        className="input-style"
                                        type="text"
                                        list="companies"
                                        value={
                                            sheetData && sheetData["company"]
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 p-0 m-0 mt-2 mb-2 ">
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <label htmlFor="forman">Foreman</label>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <input
                                        className="input-style"
                                        type="text"
                                        id="foreman"
                                        name="foreman"
                                        value={
                                            sheetData && sheetData["foreman"]
                                        }
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row p-0 m-0 d-flex justify-content-center clr-gray">
                            <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 p-0 m-0 mt-2 mb-2 ">
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <label htmlFor="po">P.O.</label>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <input
                                        className="input-style wdth-240"
                                        type="text"
                                        list="pos"
                                        value={sheetData && sheetData["po"]}
                                        disabled
                                    />
                                    {/* <datalist id="pos">
                                        {PoListOptions.map((item, index) => (
                                            <option value={item} key={index}>
                                                {item}
                                            </option>
                                        ))}
                                    </datalist> */}
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 p-0 m-0 mt-2 mb-2 ">
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <label className="" htmlFor="invoice">
                                        Invoice
                                    </label>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <input
                                        className="input-style"
                                        type="text"
                                        id="invoice"
                                        name="invoice"
                                        disabled
                                        value={
                                            sheetData && sheetData["invoice"]
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 p-0 m-0 mt-2 mb-2 ">
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <label className="" htmlFor="status">
                                        Status
                                    </label>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <input
                                        className="input-style"
                                        type="text"
                                        id="status"
                                        name="status"
                                        value={sheetData && sheetData["status"]}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row p-0 m-0 d-flex justify-content-center clr-gray mrg-bt-60">
                            <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 p-0 m-0 mt-2 mb-2 ">
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <label htmlFor="order_number">
                                        Order Number{" "}
                                    </label>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <input
                                        className="input-style wdth-240"
                                        type="text"
                                        id="order_number"
                                        name="order_number"
                                        value={
                                            sheetData &&
                                            sheetData["order_number"]
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 p-0 m-0 mt-2 mb-2 ">
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <label htmlFor="company">Cost Center</label>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <input
                                        className="input-style"
                                        type="text"
                                        id="cost_center"
                                        name="cost_center"
                                        disabled
                                        value={
                                            sheetData &&
                                            sheetData["cost_center"]
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 p-0 m-0 mt-2 mb-2 ">
                                {/* <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <label htmlFor="company">Viewer</label>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                    <select
                                        className="input-style"
                                        name="viewer"
                                        disabled
                                        defaultValue={
                                            sheetData &&
                                            sheetData["viewer_mail"]
                                        }
                                        value={
                                            sheetData &&
                                            sheetData["viewer_mail"]
                                        }
                                    >
                                        <option>
                                            {sheetData &&
                                                sheetData["viewer_mail"]}
                                        </option>
                                        
                                    </select>
                                </div> */}
                            </div>
                        </div>
                        {daysData && (
                            <>
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row p-0 m-0  mt-20 mlr-5p">
                                    <MDBTable responsive>
                                        <MDBTableHead>
                                            <tr>
                                                <th className="text-center bg-lght-gray pt-2  pb-2 table-timesheet">
                                                    DAY
                                                </th>
                                                <th className="text-center bg-lght-gray pt-2 pb-2 table-timesheet ">
                                                    DATE
                                                </th>
                                                <th className="text-center bg-lght-gray pt-2 pb-2 table-timesheet">
                                                    HOURS
                                                </th>
                                                <th className="text-center bg-lght-gray pt-2 pb-2 table-timesheet">
                                                    RATE
                                                </th>
                                                <th className="text-center bg-lght-gray pt-2 pb-2 table-timesheet">
                                                    PERIOD
                                                </th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {daysData.map((Data, index) => (
                                                <tr
                                                    key={index}
                                                    className="table-timesheet"
                                                >
                                                    <td className="text-center pt-2 pb-2 table-timesheet ">
                                                        {Data.day}
                                                    </td>
                                                    <td className="text-center pt-2 pb-2 table-timesheet width-15">
                                                        {Data.date}
                                                    </td>
                                                    <td className="text-center pt-2 pb-2 table-timesheet ">
                                                        <input
                                                            className="small-input form-control table-input-box d-flex mx-auto width-57"
                                                            type="number"
                                                            name="hours"
                                                            placeholder="Eg : 4.5"
                                                            disabled
                                                            key={Data.hours}
                                                            value={
                                                                Data.date ===
                                                                hrsDate
                                                                    ? hrsVal
                                                                    : Data.hours
                                                            }
                                                            onChange={(event) =>
                                                                handlehourschange(
                                                                    event,
                                                                    Data.date
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td className="text-center pt-2 pb-2 table-timesheet wdth-25">
                                                        <select
                                                            className="form-control table-input-box styled-select d-flex mx-auto"
                                                            name="rate"
                                                            value={Data.rate}
                                                            disabled
                                                        >
                                                            {rateList.map(
                                                                (item, key) => (
                                                                    <option
                                                                        value={
                                                                            item.value
                                                                        }
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        {
                                                                            item.value
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </td>
                                                    <td className="text-center pt-2 pb-2 wdth-25">
                                                        <select
                                                            className="form-control table-input-box d-flex mx-auto"
                                                            name="period"
                                                            value={Data.period}
                                                            disabled
                                                        >
                                                            {periodList.map(
                                                                (data, key) => (
                                                                    <option
                                                                        key={
                                                                            key
                                                                        }
                                                                        value={
                                                                            data.key
                                                                        }
                                                                    >
                                                                        {
                                                                            data.value
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                                <td className="brdr-0 pt-2 pb-2 tr-fnt-size">
                                                    TOTAL HOURS
                                                </td>
                                                <td className="pt-2 pb-2 table-timesheet text-center">
                                                    {sheetData["total_hours"]}
                                                </td>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                                <td className="brdr-0 pt-2 pb-2 tr-fnt-size">
                                                    DAYS PER MONTH
                                                </td>
                                                <td className="pt-2 pb-2 table-timesheet text-center">
                                                    {
                                                        sheetData[
                                                            "days_per_month"
                                                        ]
                                                    }
                                                </td>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                                <td className="brdr-0 pt-2 pb-2 tr-fnt-size">
                                                    PER DAY
                                                </td>
                                                <td className="pt-2 pb-2 table-timesheet text-center">
                                                    {sheetData["per_day"]}
                                                </td>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                                <td className="brdr-0 pt-2 pb-2 tr-fnt-size">
                                                    TOTAL
                                                </td>
                                                <td className="pt-2 pb-2 table-timesheet text-center">
                                                    {sheetData["total_hours"]}
                                                </td>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                                <td className="brdr-0 pt-2 pb-2"></td>
                                            </tr>
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>

                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12  row mt-20">
                                    <div className="col-sm-12 col-md-12 col-lg-6 float-right row">
                                        {/* <MDBBtn
                                            onClick={() => submit("Complete")}
                                            disabled={
                                                timesheet_id &&
                                                isSubmittable &&
                                                isMonthChanged === false
                                                    ? false
                                                    : true
                                            }
                                            className={
                                                timesheet_id && isSubmittable
                                                    ? "submit-btn save-button"
                                                    : "save-btn-disabled save-button"
                                            }
                                            type="submit"
                                        >
                                            {timesheet_loading && (
                                                <span className="fa fa-spinner fa-spin"></span>
                                            )}{" "}
                                            Submit Timesheet
                                        </MDBBtn>
                                        <MDBBtn
                                            onClick={() =>
                                                submit("In-progress")
                                            }
                                            disabled={
                                                timesheet_loading ? true : false
                                            }
                                            className={
                                                timesheet_loading
                                                    ? "save-btn-disabled save-button"
                                                    : "save-btn save-button"
                                            }
                                            type="submit"
                                        >
                                            {timesheet_loading && (
                                                <span className="fa fa-spinner fa-spin"></span>
                                            )}{" "}
                                            Save Changes
                                        </MDBBtn> */}
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-5 mr-top-155">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                                            <SignaturePad
                                                disabled
                                                ref={sigCanvas}
                                                canvasProps={{
                                                    className:
                                                        "signatureCanvas",
                                                }}
                                            />
                                            <div className="signature-style">
                                                <span className="pr-2">
                                                    SIGNATURE
                                                </span>
                                            </div>
                                            <div className="">
                                                <span
                                                    className="clear-btn"
                                                    type="clearSignature"
                                                    id="clearSignature"
                                                    onClick={clearSignature}
                                                >
                                                    Clear
                                                </span>
                                            </div>

                                            <input
                                                placeholder="Name"
                                                className="input-style2 p-2 mt-3"
                                                type="text"
                                                id="manager_name"
                                                name="manager_name"
                                                value={
                                                    sheetData["manager_name"]
                                                }
                                                disabled
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 clr-gray manager-name">
                                            {/* <label className="mr-l">
                                            Manager Name
                                        </label> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12  mr-b-50  mt-20">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 row d-flex mx-auto">
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12">
                                    <label className=" clr-gray">
                                        Message :
                                    </label>
                                </div>
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12">
                                    <textarea
                                        className=" message-area     "
                                        name="postContent"
                                        onChange={(e) =>
                                            setSheetMessage(e.target.value)
                                        }
                                        placeholder="Type your message here ....."
                                        rows={5}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12   row mt-20">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 row d-flex mx-auto">
                                <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6 d-flex mx-auto">
                                    <MDBBtn
                                        onClick={() => submit("Audit")}
                                        className={"audit-and-approve-btn"}
                                    >
                                        {timesheet_loading && (
                                            <span className="fa fa-spinner fa-spin"></span>
                                        )}{" "}
                                        Audit
                                    </MDBBtn>
                                </div>
                                <div className="col-sm-4 col-lg-4 col-xs-4 col-md-4 d-flex mx-auto">
                                    <MDBBtn
                                        onClick={() => submit("Approve")}
                                        className={"audit-and-approve-btn"}
                                    >
                                        {timesheet_loading && (
                                            <span className="fa fa-spinner fa-spin"></span>
                                        )}{" "}
                                        Approve
                                    </MDBBtn>
                                </div>
                            </div>
                        </div>
                        <div
                            className="clr-red col-sm-12 col-lg-12 col-xs-12 col-md-12 d-flex justify-content-center mt-20"
                            id="msg_div"
                        >
                            <ul>
                                {validationMessages.map((vm) => (
                                    <li key={vm}>{vm}</li>
                                ))}
                            </ul>
                        </div>

                        {alertMsg === true &&
                            alert_type === "TIMESHEET_SUCCESS" && (
                                <div>
                                    <p className="success-msg ">
                                        Timesheet Saved Successfully
                                    </p>
                                </div>
                            )}
                        {isAlertVisible === true && (
                            <div>
                                <p className="success-msg ">
                                    Timesheet Submitted Successfully
                                </p>
                            </div>
                        )}
                        {alert_type === "TIMESHEET_FAILURE" && (
                            <div>
                                <p className="fail-msg">
                                    Something went wrong, Please try again
                                </p>
                            </div>
                        )}
                    </MDBContainer>
                </div>
            </main>
        </>
    );
};

export default TimeSheetPrefilled;
