import { documentConstants } from "../actions/types/document.constants";

export function DocumentList(state = {}, actions) {
  switch (actions.type) {
    case documentConstants.GET_DOCUMENT_REQUEST:
      return {
        document_loading: true,
        data: null,
      };
    case documentConstants.GET_DOCUMENT_SUCCESS:
      return {
        doc_type_list: actions.document_list.data["doc_type_list"],
        data: actions.document_list.data["doc_data"],
        mach_list: actions.document_list.data["mach_list"],
        mach_sub_list: actions.document_list.data["mach_sub_list"],
        hire_list: actions.document_list.data["hire_list"],
        yearList: actions.document_list.data["hire_list"],
        file_type: actions.document_list.data["file_type"],
        machine: actions.document_list.data["machine"],
        foreman: actions.document_list.data["foreman"],
        company: actions.document_list.data["company"],
        machine_data: actions.document_list.data["doc_machine"],
        no_of_pages: actions.document_list.data["no_of_pages"],
        audit_data: actions.document_list.data["audit_data"],
        total: actions.document_list.data["total_count"],
        offset: actions.document_list.data["offset"],
        upto: actions.document_list.data["upto"],
        document_loading: false,
      };
    case documentConstants.GET_DOCUMENT_FAILURE:
      return {
        document_loading: false,
        data: null,
      };
    default:
      return state;
  }
}
