import * as config from "../config/config";
import { authHeader } from "../services/http.header";

export const userService = {
  login,
  getUserData,
  MicrosoftLogin,
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${config.apiUrl}/users/login/`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function getUserData() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/users/me/`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
function MicrosoftLogin(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };

  return fetch(`${config.apiUrl}/users/microsoft-login/`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}
