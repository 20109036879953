import { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import classNames from "classnames";
import "react-calendar/dist/Calendar.css";
import { useDispatch } from "react-redux";
import { anomalieActions } from "../store/actions/anomalies.actions";
import { machineActions } from "../store/actions/machine.action";
import { documentActions } from "../store/actions/document.actions";
import { timesheetActions } from "../store/actions/timeSheet.actions";
import { handleLongString } from "../helpers/Helper";

const SelectOptionDropDown = ({
    defaultText,
    optionsList,
    reset,
    calendar,
    updatePageNo,
    mchn,
}) => {
    const dispatch = useDispatch();
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedMachineType, setSelectedMachineType] = useState(null);
    const [selectedMachineSubType, setSelectedMachineSubType] = useState(null);
    const [searchItem, setSearchItem] = useState(null);
    const [fileType, setFiletype] = useState(null);
    const [docFileType, setDocFiletype] = useState(null);
    const [companyType, setCompanyType] = useState(null);
    const [foremanType, setForemanType] = useState(null);
    const [selectdYear, setSelectdYear] = useState(null);
    const [machineType, setMachineType] = useState(null);
    const [docMachineType, setDocMachineType] = useState(null);
    const [docCompany, setDocCompany] = useState(null);

    const [defaultSelectText, setDefaultSelectText] = useState("");
    const [showOptionList, setShowOptionList] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [isCalendar, setIsCalendar] = useState("");
    const [date, setDate] = useState(new Date());
    const [view, setView] = useState("year");
    const [datePicked, setDatePicked] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(dateText());
    const [selectedDate, setselectedDate] = useState(null);
    const [selectedMachCompany, setSelectedMachCompany] = useState(null);
    const [disabledSubType, setDisabledSubType] = useState(true);
    const [anomaliesStatus, setAnomaliesStatus] = useState(null);
    const [anomlaiesCompany, setAnomaliesCompany] = useState(null);

    function dateText() {
        if (defaultText.toLowerCase().includes("date")) {
            return defaultText;
        } else {
            return "";
        }
    }

    const months = [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    const onClickMonth = () => {
        setView("year");
    };

    useEffect(() => {
        setIsCalendar(calendar);
    }, [calendar]);

    useEffect(() => {
        setDefaultSelectText(defaultText);
        setSelectedSite(
            localStorage.getItem("site") ? localStorage.getItem("site") : null
        );
    }, [defaultText]);

    useEffect(() => {
        if (reset) {
            localStorage.removeItem("site");
            localStorage.removeItem("file_type");
            localStorage.removeItem("document");
            localStorage.removeItem("status");
            localStorage.removeItem("machineType");
            localStorage.removeItem("machineSubtype");
            localStorage.removeItem("machine");
            localStorage.removeItem("doc-machine");
            localStorage.removeItem("foreman");
            localStorage.removeItem("filetype");
            localStorage.removeItem("company");
            localStorage.removeItem("date");
            localStorage.removeItem("start_date");
            localStorage.removeItem("end_date");
            localStorage.removeItem("sheetStatus");
            localStorage.removeItem("sheet_start_date");
            localStorage.removeItem("sheet_end_date");
            localStorage.removeItem("year");
            localStorage.removeItem("docComapny");
            localStorage.removeItem("machComapny");
            localStorage.removeItem("anm_status");
            localStorage.removeItem("anm_start_date");
            localStorage.removeItem("anm_end_date");
            localStorage.removeItem("anmComapny");

            setDatePicked(false);
            setDefaultSelectText(defaultText);
            setSearchTerm("");
        }
    }, [reset, defaultText]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        setDefaultSelectText(defaultText);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [defaultText]);

    const handleClickOutside = (e) => {
        if (
            !e.target.closest(".calendar") &&
            !e.target.classList.contains("custom-select-option") &&
            !e.target.classList.contains("selected-text") &&
            !e.target.classList.contains("select-filter")
        ) {
            setShowOptionList(false);
        }
    };

    const handleListDisplay = () => {
        setShowOptionList((prevShowOptionList) => !prevShowOptionList);
    };

    const HandleMonthChange = (e) => {
        localStorage.setItem("date", e);
        // dispatch(
        //     anomalieActions.getAnomalielist(
        //         selectedSite,
        //         selectedDoc,
        //         selectedStatus,
        //         e,
        //         1
        //     )
        // );
        dispatch(
            documentActions.getDocumentlist(
                fileType,
                localStorage.getItem("docComapny")
                    ? localStorage.getItem("docComapny")
                    : null,
                foremanType,
                machineType,
                1,
                localStorage.getItem("start_date")
                    ? localStorage.getItem("start_date")
                    : null,
                localStorage.getItem("end_date")
                    ? localStorage.getItem("end_date")
                    : null
            )
        );
    };

    const handleOptionClick = (e) => {
        setDefaultSelectText(e.target.getAttribute("data-name"));
        setShowOptionList(false);
        if (e.target.getAttribute("data-type") === "anm_status") {
            updatePageNo(1);
            localStorage.setItem(
                "anm_status",
                e.target.getAttribute("data-name")
            );
            setAnomaliesStatus(e.target.getAttribute("data-name"));
            dispatch(
                anomalieActions.getAnomalielist(
                    null,
                    null,
                    e.target.getAttribute("data-name"),
                    localStorage.getItem("anm_start_date")
                        ? localStorage.getItem("anm_start_date")
                        : null,
                    localStorage.getItem("anm_end_date")
                        ? localStorage.getItem("anm_end_date")
                        : null,
                    localStorage.getItem("anmComapny")
                        ? localStorage.getItem("anmComapny")
                        : null,
                    1
                )
            );
        }

        if (e.target.getAttribute("data-type") === "anmComapny") {
            updatePageNo(1);
            localStorage.setItem(
                "anmComapny",
                e.target.getAttribute("data-name")
            );

            setAnomaliesCompany(e.target.getAttribute("data-name"));
            dispatch(
                anomalieActions.getAnomalielist(
                    null,
                    null,
                    localStorage.getItem("anm_status")
                        ? localStorage.getItem("anm_status")
                        : null,
                    localStorage.getItem("anm_start_date")
                        ? localStorage.getItem("anm_start_date")
                        : null,
                    localStorage.getItem("anm_end_date")
                        ? localStorage.getItem("anm_end_date")
                        : null,
                    e.target.getAttribute("data-name"),
                    1
                )
            );
        }
        if (e.target.getAttribute("data-type") === "document") {
            localStorage.setItem(
                "document",
                e.target.getAttribute("data-name")
            );

            setSelectedDoc(e.target.getAttribute("data-name"));
            dispatch(
                anomalieActions.getAnomalielist(
                    selectedSite,
                    e.target.getAttribute("data-name"),
                    selectedStatus,
                    selectedDate,
                    1
                )
            );
        }
        if (e.target.getAttribute("data-type") === "machineType") {
            localStorage.setItem(
                "machineType",
                e.target.getAttribute("data-name")
            );

            setSelectedMachineType(e.target.getAttribute("data-name"));
            dispatch(
                machineActions.getMachinelist(
                    e.target.getAttribute("data-name"),
                    selectedMachineSubType,
                    localStorage.getItem("search")
                        ? localStorage.getItem("search")
                        : null,
                    localStorage.getItem("machComapny")
                        ? localStorage.getItem("machComapny")
                        : null,
                    1
                )
            );
        }
        if (e.target.getAttribute("data-type") === "machComapny") {
            localStorage.setItem(
                "machComapny",
                e.target.getAttribute("data-name")
            );

            setSelectedMachCompany(e.target.getAttribute("data-name"));
            dispatch(
                machineActions.getMachinelist(
                    localStorage.getItem("machineType")
                        ? localStorage.getItem("machineType")
                        : null,
                    selectedMachineSubType,
                    localStorage.getItem("search")
                        ? localStorage.getItem("search")
                        : null,
                    e.target.getAttribute("data-name"),

                    1
                )
            );
        }

        if (e.target.getAttribute("data-type") === "machineSubtype") {
            localStorage.setItem(
                "machineSubtype",
                e.target.getAttribute("data-name")
            );

            setSelectedMachineType(e.target.getAttribute("data-name"));
            dispatch(
                machineActions.getMachinelist(
                    selectedMachineType,
                    e.target.getAttribute("data-name"),
                    localStorage.getItem("search")
                        ? localStorage.getItem("search")
                        : null,
                    localStorage.getItem("machComapny")
                        ? localStorage.getItem("machComapny")
                        : null,
                    1
                )
            );
        }
        if (e.target.getAttribute("data-type") === "mach_type") {
            updatePageNo(1);
            localStorage.setItem(
                "doc-machine",
                e.target.getAttribute("data-name")
            );

            setDocMachineType(e.target.getAttribute("data-name"));
            dispatch(
                documentActions.getDocumentlist(
                    docFileType,
                    localStorage.getItem("docComapny")
                        ? localStorage.getItem("docComapny")
                        : null,
                    null,
                    e.target.getAttribute("data-name"),
                    1,
                    localStorage.getItem("start_date")
                        ? localStorage.getItem("start_date")
                        : null,
                    localStorage.getItem("end_date")
                        ? localStorage.getItem("end_date")
                        : null,
                    selectdYear
                )
            );
        }
        if (e.target.getAttribute("data-type") === "docComapny") {
            updatePageNo(1);
            localStorage.setItem(
                "docComapny",
                e.target.getAttribute("data-name")
            );

            setDocCompany(e.target.getAttribute("data-name"));
            dispatch(
                documentActions.getDocumentlist(
                    docFileType,
                    e.target.getAttribute("data-name"),
                    null,
                    docMachineType,
                    1,
                    localStorage.getItem("start_date")
                        ? localStorage.getItem("start_date")
                        : null,
                    localStorage.getItem("end_date")
                        ? localStorage.getItem("end_date")
                        : null,
                    selectdYear
                )
            );
        }

        if (e.target.getAttribute("data-type") === "foreman") {
            localStorage.setItem("foreman", e.target.getAttribute("data-name"));

            setForemanType(e.target.getAttribute("data-name"));
            dispatch(
                documentActions.getDocumentlist(
                    fileType,
                    localStorage.getItem("docComapny")
                        ? localStorage.getItem("docComapny")
                        : null,
                    e.target.getAttribute("data-name"),
                    machineType,
                    1,
                    localStorage.getItem("start_date")
                        ? localStorage.getItem("start_date")
                        : null,
                    localStorage.getItem("end_date")
                        ? localStorage.getItem("end_date")
                        : null
                )
            );
        }
        if (e.target.getAttribute("data-type") === "year") {
            updatePageNo(1);
            localStorage.setItem("year", e.target.getAttribute("data-name"));

            setSelectdYear(e.target.getAttribute("data-name"));
            dispatch(
                documentActions.getDocumentlist(
                    docFileType,
                    localStorage.getItem("docComapny")
                        ? localStorage.getItem("docComapny")
                        : null,
                    null,
                    localStorage.getItem("doc-machine")
                        ? localStorage.getItem("doc-machine")
                        : null,
                    1,
                    localStorage.getItem("start_date")
                        ? localStorage.getItem("start_date")
                        : null,
                    localStorage.getItem("end_date")
                        ? localStorage.getItem("end_date")
                        : null,
                    e.target.getAttribute("data-name")
                )
            );
        }

        if (e.target.getAttribute("data-type") === "file_type") {
            updatePageNo(1);
            localStorage.setItem(
                "file_type",
                e.target.getAttribute("data-name")
            );
            setDocFiletype(e.target.getAttribute("data-name"));
            dispatch(
                documentActions.getDocumentlist(
                    e.target.getAttribute("data-name"),
                    localStorage.getItem("docComapny")
                        ? localStorage.getItem("docComapny")
                        : null,
                    foremanType,
                    localStorage.getItem("doc-machine")
                        ? localStorage.getItem("doc-machine")
                        : null,
                    1,
                    localStorage.getItem("start_date")
                        ? localStorage.getItem("start_date")
                        : null,
                    localStorage.getItem("end_date")
                        ? localStorage.getItem("end_date")
                        : null,
                    selectdYear
                )
            );
        }
        // if (e.target.getAttribute("data-type") === "company") {
        //     localStorage.setItem("company", e.target.getAttribute("data-name"));

        //     setCompanyType(e.target.getAttribute("data-name"));
        //     dispatch(
        //         documentActions.getDocumentlist(
        //             docFileType,
        //             e.target.getAttribute("data-name"),
        //             foremanType,
        //             machineType,
        //             1,
        //             localStorage.getItem("start_date")
        //                 ? localStorage.getItem("start_date")
        //                 : null,
        //             localStorage.getItem("end_date")
        //                 ? localStorage.getItem("end_date")
        //                 : null
        //         )
        //     );
        // }

        if (e.target.getAttribute("data-type") === "sheetStatus") {
            localStorage.setItem(
                "sheetStatus",
                e.target.getAttribute("data-name")
            );

            // setMachineType(e.target.getAttribute("data-name"));
            dispatch(
                timesheetActions.getTimesheetlist(
                    e.target.getAttribute("data-name"),
                    localStorage.getItem("sheet_start_date")
                        ? localStorage.getItem("sheet_start_date")
                        : null,
                    localStorage.getItem("sheet_end_date")
                        ? localStorage.getItem("sheet_end_date")
                        : null
                )
            );
        }
        setShowOptionList(false);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    useEffect(() => {
        setSelectedSite(
            localStorage.getItem("site") ? localStorage.getItem("site") : null
        );
        setSelectedDoc(
            localStorage.getItem("document")
                ? localStorage.getItem("document")
                : null
        );
        setSelectedStatus(
            localStorage.getItem("status")
                ? localStorage.getItem("status")
                : null
        );
        setSelectedMachineType(
            localStorage.getItem("machineType")
                ? localStorage.getItem("machineType")
                : null
        );
        setSelectedMachineSubType(
            localStorage.getItem("machineSubtype")
                ? localStorage.getItem("machineSubtype")
                : null
        );
        setFiletype(
            localStorage.getItem("filetype")
                ? localStorage.getItem("filetype")
                : null
        );
        setDocFiletype(
            localStorage.getItem("file_type")
                ? localStorage.getItem("file_type")
                : null
        );
        setForemanType(
            localStorage.getItem("foreman")
                ? localStorage.getItem("foreman")
                : null
        );
        setMachineType(
            localStorage.getItem("machine")
                ? localStorage.getItem("machine")
                : null
        );
        setDocMachineType(
            localStorage.getItem("doc-machine")
                ? localStorage.getItem("doc-machine")
                : null
        );
        setCompanyType(
            localStorage.getItem("company")
                ? localStorage.getItem("company")
                : null
        );
        setselectedDate(
            localStorage.getItem("date") ? localStorage.getItem("date") : null
        );
        setSelectdYear(
            localStorage.getItem("year") ? localStorage.getItem("year") : null
        );
        const options = document.querySelectorAll(".custom-select-option");
        if (showOptionList) {
            options.forEach((option) => {
                option.style.display = "flex";
            });
        }
    }, [showOptionList]);

    return (
        <div
            className={classNames(
                showOptionList
                    ? isCalendar === "_calendar"
                        ? "custom-select-container_calendar"
                        : "custom-select-container active"
                    : isCalendar === "_calendar"
                    ? "custom-select-container_calendar"
                    : "custom-select-container"
            )}
        >
            <div className="selected-text" onClick={handleListDisplay}>
                {isCalendar === "_calendar"
                    ? datePicked
                        ? months[selectedMonth]
                        : dateText()
                    : handleLongString(defaultSelectText, 10)}
            </div>

            {showOptionList &&
                (isCalendar === "_calendar" ? (
                    <div className="calendar">
                        <Calendar
                            view={view}
                            onChange={setDate}
                            value={date}
                            onClickMonth={(month) => {
                                setDatePicked(true);
                                setSelectedMonth(month.getMonth());
                                onClickMonth();
                                HandleMonthChange(
                                    ` ${month.getFullYear()}-${
                                        month.getMonth() + 1
                                    }`
                                );
                            }}
                            defaultView="year"
                        />
                    </div>
                ) : (
                    <ul
                        className={
                            mchn
                                ? "select-options select-options-height2"
                                : "select-options select-options-height"
                        }
                    >
                        <div className="select-filter__wrapper">
                            <input
                                className="select-filter"
                                type="text"
                                placeholder="Type to filter"
                                onChange={handleSearch}
                            />
                        </div>
                        {optionsList &&
                            optionsList.map((option) => (
                                <li
                                    className="custom-select-option"
                                    data-name={option.value}
                                    data-type={option.type}
                                    key={option.label}
                                    onClick={handleOptionClick}
                                    style={{
                                        display:
                                            option.value
                                                .toLowerCase()
                                                .indexOf(searchTerm) !== -1
                                                ? "flex"
                                                : "none",
                                    }}
                                >
                                    {option.value}
                                </li>
                            ))}
                    </ul>
                ))}
        </div>
    );
};

export default SelectOptionDropDown;
