import { TelematicsService } from "../../services/telematics.service";
import { telematicsConstants } from "./types/telematics.constants";
export const telematicsActions = {
    getTelematicsData,
};

function getTelematicsData(
    companyType,
    foremanType,
    machineType,
    page,
    selectdMonth
) {
    return (dispatch) => {
        dispatch(request());
        TelematicsService.getTelematicslist(
            companyType,
            foremanType,
            machineType,
            page,
            selectdMonth
        ).then(
            (telematics_list) => dispatch(success(telematics_list)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: telematicsConstants.GET_TELEMATICS_REQUEST };
    }
    function success(telematics_list) {
        if (telematics_list.code === 200) {
            return {
                type: telematicsConstants.GET_TELEMATICS_SUCCESS,
                telematics_list,
            };
        } else {
            return {
                type: telematicsConstants.GET_TELEMATICS_FAILURE,
                telematics_list,
            };
        }
    }
    function failure(error) {
        return { type: telematicsConstants.GET_TELEMATICS_FAILURE, error };
    }
}
