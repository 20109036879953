import { machineConstants } from "../actions/types/machine.constants";

export function MachineList(state = {}, actions) {
  switch (actions.type) {
    case machineConstants.GET_MACHINE_REQUEST:
      return {
        machine_loading: true,
        data: null,
      };
    case machineConstants.GET_MACHINE_SUCCESS:
      return {
        data: actions.machine_list.data,
        machine_data: actions.machine_list.data["machine_data"],
        total: actions.machine_list.data["total_count"],
        offset: actions.machine_list.data["offset"],
        upto: actions.machine_list.data["upto"],
        machine_type: actions.machine_list.data["machine_type"],
        machine_sub_type: actions.machine_list.data["machine_sub_type"],
        no_of_pages: actions.machine_list.data["no_of_pages"],
        machine_loading: false,
      };
    case machineConstants.GET_MACHINE_FAILURE:
      return {
        machine_loading: false,
        data: null,
      };
    default:
      return state;
  }
}

export function MachineTypesList(state = {}, actions) {
  switch (actions.type) {
    case machineConstants.GET_MACHINE_TYPE_SUBTYPE_REQUEST:
      return {
        machine_loading: true,
        machine_type: null,
        machine_sub_type: null,
      };
    case machineConstants.GET_MACHINE_TYPE_SUBTYPE_SUCCESS:
      return {
        machine_type: actions.machine_type_list.data["types"],
        machine_sub_type: actions.machine_type_list.data["sub_types"],
      };
    case machineConstants.GET_MACHINE_TYPE_SUBTYPE_FAILURE:
      return {
        machine_loading: false,
        machine_type: null,
        machine_sub_type: null,
      };
    default:
      return state;
  }
}

export function AddMachine(state = {}, actions) {
  switch (actions.type) {
    case machineConstants.SAVE_MACHINE_REQUEST:
      return {
        machine_loading: true,
        msg: null,
        code: null,
      };
    case machineConstants.SAVE_MACHINE_SUCCESS:
      return {
        machine_loading: false,
        code: actions.machine_res.machine_res.code,
        msg: actions.machine_res.machine_res.message,
      };
    case machineConstants.SAVE_MACHINE_FAILURE:
      return {
        machine_loading: false,
        code: actions.machine_res.machine_res.code,
        msg: actions.machine_res.machine_res.message,
      };
    default:
      return state;
  }
}

export function getMachineMessagesById(state = {}, actions) {
  switch (actions.type) {
    case machineConstants.GET_MACHINE_MESSAGE_REQUEST:
      return {
        data: null,
        msg_loading: true,
      };
    case machineConstants.GET_MACHINE_MESSAGE_SUCCESS:
      return {
        data: actions.mchn_msg_data.data["data_list"],
        // machine_counts: actions.mchn_msg_data.data["machine_count"],
        msg_loading: false,
      };
    case machineConstants.GET_MACHINE_MESSAGE_FAILURE:
      return {
        data: null,
        msg_loading: false,
      };
    default:
      return state;
  }
}

export function getMachineType(state = {}, actions) {
  switch (actions.type) {
    case machineConstants.GET_MACHINE_OPTIONS_REQUEST:
      return {
        data: null,
        data_loading: true,
      };
    case machineConstants.GET_MACHINE_OPTIONS_SUCCESS:
      return {
        type_list: actions.mchn_option_data.data["typeList"],
        imie_list: actions.mchn_option_data.data["imeiList"],
        v_codeListList: actions.mchn_option_data.data["v_codeListList"],

        data_list: actions.mchn_option_data.data["data_dict"],
        data_loading: false,
      };
    case machineConstants.GET_MACHINE_OPTIONS_FAILURE:
      return {
        data: null,
        data_loading: false,
      };
    default:
      return state;
  }
}
export function getMachineImeiNo(state = {}, actions) {
  switch (actions.type) {
    case machineConstants.GET_MACHINE_OPTIONS2_REQUEST:
      return {
        data: null,
        data_loading: true,
      };
    case machineConstants.GET_MACHINE_OPTIONS2_SUCCESS:
      return {
        imei_list: actions.mchn_option_data.data,
        data_loading: false,
      };
    case machineConstants.GET_MACHINE_OPTIONS2_FAILURE:
      return {
        data: null,
        data_loading: false,
      };
    default:
      return state;
  }
}

export function getVehicleData(state = {}, actions) {
  switch (actions.type) {
    case machineConstants.GET_VEHICLE_DATA_REQUEST:
      return {
        data: null,
        data_loading: true,
      };
    case machineConstants.GET_VEHICLE_DATA_SUCCESS:
      return {
        data: actions.vehicle_data.data,
        data_loading: false,
      };
    case machineConstants.GET_VEHICLE_DATA_FAILURE:
      return {
        data: null,
        data_loading: false,
      };
    default:
      return state;
  }
}

export function getMachineDataById(state = {}, actions) {
  switch (actions.type) {
    case machineConstants.GET_VEHICLE_DATA_BY_ID_REQUEST:
      return {
        data: null,
        data_loading: true,
      };
    case machineConstants.GET_VEHICLE_DATA_BY_ID_SUCCESS:
      return {
        data: actions.machine_data.data,
        data_loading: false,
      };
    case machineConstants.GET_VEHICLE_DATA_BY_ID_FAILURE:
      return {
        data: null,
        data_loading: false,
      };
    default:
      return state;
  }
}

export function DeleteMachineDataById(state = {}, actions) {
  switch (actions.type) {
    case machineConstants.DELETE_MACHINE_REQUEST:
      return {
        data: null,
        data_loading: true,
      };
    case machineConstants.DELETE_MACHINE_SUCCESS:
      return {
        data: actions.delete_data.data,
        delete_code: actions.delete_data.data,
        data_loading: false,
      };
    case machineConstants.DELETE_MACHINE_FAILURE:
      return {
        data: null,
        data_loading: false,
      };
    default:
      return state;
  }
}
