import React from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import "reactjs-popup/dist/index.css";

function LoginNavBar() {
    return (
        <>
            <main className="top-border-box nav_header">
                <section className="header__content ">
                    <Link to="/">
                        <img
                            className="logo m-l-34"
                            src={logo}
                            alt="Timesheet"
                        />
                    </Link>
                </section>
            </main>
        </>
    );
}

export default LoginNavBar;
