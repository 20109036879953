import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { machineActions } from "../../store/actions/machine.action";

export function MachineContractor({contractorList,updatePageNo}) {
  const dispatch = useDispatch();
  const contractor_list = contractorList && contractorList !== 'undefined' && contractorList !== undefined ?contractorList:[]

  const handleOptionClick = (machComapny) => {
    updatePageNo(1);
    localStorage.setItem(
      "machComapny",
      machComapny
  );
  dispatch(
    machineActions.getMachinelist(
      localStorage.getItem("machineType")
      ? localStorage.getItem("machineType")
      : null,
      localStorage.getItem("machineSubtype")
      ? localStorage.getItem("machineSubtype")
      : null,
      localStorage.getItem("search")
      ? localStorage.getItem("search")
      : null,
      machComapny,

        1
    )
);
}

  const [selected, setSelected] = useState(
    null
  );
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-2 min-w-[10rem]">
            <Listbox.Button className="f-btn relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#FCAF17] sm:text-sm sm:leading-6">
              <span className="flex items-center space-x-1.5 truncate">
                {!localStorage.getItem("machComapny") && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="none"
                  >
                    <path
                      fill="#657488"
                      d="M10 10a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 7 7c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 10 4c.833 0 1.542.292 2.125.875S13 6.167 13 7s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 10 10Zm-6 6v-2c0-.32.087-.621.26-.906a2.22 2.22 0 0 1 .72-.719 10.242 10.242 0 0 1 2.422-1.02 9.662 9.662 0 0 1 5.192 0c.854.235 1.663.576 2.427 1.02.305.18.545.417.719.708.173.292.26.598.26.917v2H4Zm1.5-1.5h9V14a.33.33 0 0 0-.063-.196.422.422 0 0 0-.166-.137 7.812 7.812 0 0 0-2.042-.875A8.535 8.535 0 0 0 10 12.5c-.764 0-1.507.097-2.23.292a7.812 7.812 0 0 0-2.04.875c-.07.055-.126.109-.168.16A.268.268 0 0 0 5.5 14v.5Zm4.504-6c.414 0 .767-.147 1.059-.442.291-.295.437-.649.437-1.062 0-.414-.147-.767-.442-1.059A1.456 1.456 0 0 0 9.996 5.5c-.414 0-.767.147-1.059.442A1.456 1.456 0 0 0 8.5 7.004c0 .414.147.767.442 1.059.295.291.649.437 1.062.437Z"
                    />
                  </svg>
                )}
                <span className="fs-14 f-text">{localStorage.getItem("machComapny")
                        ? localStorage.getItem("machComapny")
                         : "Contractor"}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 f-icon">
                <ChevronDownIcon
                  className="hw-2 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="fs-14 absolute z-10 mt-1 max-h-dp w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {contractor_list && contractor_list.length>0 ? contractor_list.map((item) => (
                  <Listbox.Option
                    title={item.label}
                    key={item.label}
                    className={({ active }) =>
                      clsx(
                        active ? "bg-gray-50" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item.value}
                    onClick={() => handleOptionClick(item.value)}
                  >
                    {({ selected }) => (
                      <div className="flex items-center space-x-2">
                        <span
                          className={clsx(
                            selected && localStorage.getItem("machComapny")? "bg-[#FCAF17]" : "bg-gray-200",
                            "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                          )}
                        />
                        <span
                          className={clsx(
                            selected && localStorage.getItem("machComapny") ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item.value}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                )):null}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
