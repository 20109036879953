/**
 * View Timesheet page
 */

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { EyeIcon } from "@heroicons/react/24/outline";
import { useState, useEffect, useRef } from "react";
// import { Modal } from "../components/modals/timesheet";
// import { SelectedDate } from "./components/selectors/selectedDate";
// import { Status } from "./components/selectors/status";
import clsx from "clsx";
import SideBar from "./SideBar";
import { timesheetActions } from "../store/actions/timeSheet.actions";
import { useDispatch, useSelector } from "react-redux";
import { handleLongString } from "../helpers/Helper";
import { handleLongDateString } from "../helpers/Helper";
import { Status } from "./TimesheetSelectors/status";
import { SelectedDate } from "./TimesheetSelectors/selectedDate";
import { Modal } from "../components/modals/timesheet";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { userActions } from "../store/actions/user.actions";
import { calenderDays } from "../config/config";
import { TimesheetContractor } from "./TimesheetSelectors/contractor";
import PaginationCustom from "./PaginationCustom2";
import PaginationPageNum from "./PaginationPageNum2";
import getOptions from "../services/http.header";
import axios from "axios";
import { VehicleCode } from "./TimesheetSelectors/VehicleCode";
import * as config from "../config/config";

export default function ViewTimesheet() {
  const timeSheetStatus = window.location.href.split("status=")[1];

  const offset = useSelector((state) => state.TimesheetList.offset);
  const upto = useSelector((state) => state.TimesheetList.upto);
  const total = useSelector((state) => state.TimesheetList.total);
  const no_of_pages = useSelector((state) => state.TimesheetList.no_of_pages);
  const vehicle_code_list = useSelector(
    (state) => state.OptionsList.vehicle_code_list2
  );
  const companyList = [
    {
      label: "Red Top Asset Management",
      value: "Red Top Asset Management",
      type: "docComapny",
    },
    {
      label: "Quartz Plant Hire",
      value: "Quartz Plant Hire",
      type: "docComapny",
    },
    {
      label: "KLT Machinery and Plant Hire",
      value: "KLT Machinery and Plant Hire",
      type: "docComapny",
    },
  ];

  const user_dt = useSelector((state) => state.users);
  const [searchValue, setSearchValue] = useState(null);
  const [searchItem, setSearchitem] = useState("");
  const [page, setPage] = useState(1);
  const updatePageNo = (newState) => {
    setPage(newState);
  };

  const user_role =
    user_dt.items !== undefined && user_dt.items["role_detail"]["role"];
  const selectedsheetid = window.location.href.split("id=")[1];
  const MonthName = (monthNumber) => {
    const monthName = new Date(2023, monthNumber - 1).toLocaleString(
      "default",
      { month: "long" }
    );

    return <div>{monthName}</div>;
  };
  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    const monthName = date.toLocaleString("en-US", {
      month: "long",
    });

    return <div>{monthName}</div>;
  }
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [placeholder, setplaceholder] = useState("Date");

  const timesheet_list_loading = useSelector(
    (state) => state.TimesheetList.timesheet_loading
  );
  const timesheet_list = useSelector((state) => state.TimesheetList.data);

  const HandleClearFilters = () => {
    handleReset();
    setplaceholder("Date");
    HandleSearchChange2("");
    // dispatch(timesheetActions.getTimesheetlist());
    localStorage.removeItem("tms_search");
    localStorage.removeItem("tms_search");
    localStorage.removeItem("sheetStatus");
    localStorage.removeItem("sheet_start_date");
    localStorage.removeItem("sheet_end_date");
    localStorage.removeItem("sheetComapny");
    localStorage.removeItem("VehicleCode");

    handleCallback(startDate, today);
  };
  const [TimesheetData, setTimesheetData] = useState(null);
  const openDocumentModal = (timesheet_data, isApproved) => {
    if (
      (user_role === "Contractor" || user_role === "Subcontractor") &&
      isApproved === false
    ) {
      window.location.assign(`${"/edit-timesheet"}/id=${timesheet_data.id}`);
    } else {
      setOpen(true);
      setTimesheetData(timesheet_data);
    }
  };
  const handleCallback = (start, end, label) => {
    let s_date = null;
    let e_date = null;
    setPage(1);
    dispatch(timesheetActions.getOptionsList());
    s_date = moment(start).format("DD-MM-YYYY");
    localStorage.setItem(
      "sheet_start_date",
      moment(start).format("DD-MM-YYYY")
    );
    setplaceholder(
      `${moment(start).format("DD-MM-YY")}~${moment(end).format("DD-MM-YY")} `
    );
    e_date = moment(end).format("DD-MM-YYYY");
    localStorage.setItem("sheet_end_date", moment(end).format("DD-MM-YYYY"));
    dispatch(
      timesheetActions.getTimesheetlist(
        localStorage.getItem("sheetStatus")
          ? localStorage.getItem("sheetStatus")
          : null,
        s_date,
        e_date,
        localStorage.getItem("tms_search")
          ? localStorage.getItem("tms_search")
          : null,
        localStorage.getItem("sheetComapny")
          ? localStorage.getItem("sheetComapny")
          : null,
        1,
        localStorage.getItem("VehicleCode")
          ? localStorage.getItem("VehicleCode")
          : null
      )
    );
  };

  const handleCallback2 = (start, end, sheet_status) => {
    localStorage.setItem("sheetStatus", sheet_status);
    let s_date = null;
    let e_date = null;
    s_date = moment(start).format("DD-MM-YYYY");
    localStorage.setItem(
      "sheet_start_date",
      moment(start).format("DD-MM-YYYY")
    );
    setplaceholder(
      `${moment(start).format("DD-MM-YY")}~${moment(end).format("DD-MM-YY")} `
    );
    e_date = moment(end).format("DD-MM-YYYY");
    localStorage.setItem("sheet_end_date", moment(end).format("DD-MM-YYYY"));
    dispatch(
      timesheetActions.getTimesheetlist(
        sheet_status,
        s_date,
        e_date,
        localStorage.getItem("tms_search")
          ? localStorage.getItem("tms_search")
          : null,
        localStorage.getItem("sheetComapny")
          ? localStorage.getItem("sheetComapny")
          : null,
        1,
        localStorage.getItem("VehicleCode")
          ? localStorage.getItem("VehicleCode")
          : null
      )
    );
  };
  const today = new Date();
  // const startDate = new Date();
  // startDate.setDate(today.getDate() - calenderDays);
  const startDate = new Date(today.getFullYear(), today.getMonth(), 1);

  const [selectedRange, setSelectedRange] = useState(null);
  const dateRangePickerRef = useRef(null);

  const handleReset = () => {
    const { current: picker } = dateRangePickerRef;
    if (picker) {
      picker.setStartDate(startDate);
      picker.setEndDate(today);
      setSelectedRange(null);
    }
  };

  const [dateRange, setDateRange] = useState({
    startDate,
    endDate: today,
    maxDate: today,
  });

  const HandleSearchChange2 = (search) => {
    setSearchitem(search);
    setSearchValue(search);
  };

  const HandleSearchChange = (search) => {
    localStorage.setItem("tms_search", search);
    setSearchitem(search);
    setPage(1);

    setSearchValue(search);

    dispatch(
      timesheetActions.getTimesheetlist(
        localStorage.getItem("sheetStatus")
          ? localStorage.getItem("sheetStatus")
          : null,
        localStorage.getItem("sheet_start_date")
          ? localStorage.getItem("sheet_start_date")
          : null,
        localStorage.getItem("sheet_end_date")
          ? localStorage.getItem("sheet_end_date")
          : null,
        localStorage.getItem("tms_search")
          ? localStorage.getItem("tms_search")
          : null,
        localStorage.getItem("sheetComapny")
          ? localStorage.getItem("sheetComapny")
          : null,
        1,
        localStorage.getItem("VehicleCode")
          ? localStorage.getItem("VehicleCode")
          : null
      )
    );
  };

  const handlePageChange2 = (pageNo) => {
    setPage(pageNo);
    dispatch(
      timesheetActions.getTimesheetlist(
        localStorage.getItem("sheetStatus")
          ? localStorage.getItem("sheetStatus")
          : null,
        localStorage.getItem("sheet_start_date")
          ? localStorage.getItem("sheet_start_date")
          : null,
        localStorage.getItem("sheet_end_date")
          ? localStorage.getItem("sheet_end_date")
          : null,
        localStorage.getItem("tms_search")
          ? localStorage.getItem("tms_search")
          : null,
        localStorage.getItem("sheetComapny")
          ? localStorage.getItem("sheetComapny")
          : null,

        pageNo,
        localStorage.getItem("VehicleCode")
          ? localStorage.getItem("VehicleCode")
          : null
      )
    );
  };
  const renderPageNumbers = () => {
    const elements = [];
    let new_no_of_pages = 1;
    let page_start = 1;
    if (no_of_pages > 4) {
      page_start = page;
      new_no_of_pages += 2 + page;
      if (new_no_of_pages >= no_of_pages) {
        new_no_of_pages = no_of_pages;
      }
      let page_diff = no_of_pages - page_start;

      if (page_diff <= 3) {
        page_start = no_of_pages - 4;
      }
    } else {
      new_no_of_pages = no_of_pages;
    }

    for (let i = page_start; i <= new_no_of_pages; i++) {
      elements.push(
        <PaginationPageNum
          key={i}
          active={page === i}
          onClick={() => handlePageChange2(i)}
        >
          {i}
        </PaginationPageNum>
      );
    }
    return elements;
  };

  const handlePageChange = (pageNo) => {
    if (pageNo === "-") {
      dispatch(
        timesheetActions.getTimesheetlist(
          localStorage.getItem("sheetStatus")
            ? localStorage.getItem("sheetStatus")
            : null,
          localStorage.getItem("sheet_start_date")
            ? localStorage.getItem("sheet_start_date")
            : null,
          localStorage.getItem("sheet_end_date")
            ? localStorage.getItem("sheet_end_date")
            : null,
          localStorage.getItem("tms_search")
            ? localStorage.getItem("tms_search")
            : null,

          localStorage.getItem("sheetComapny")
            ? localStorage.getItem("sheetComapny")
            : null,
          page - 1,
          localStorage.getItem("VehicleCode")
            ? localStorage.getItem("VehicleCode")
            : null
        )
      );
    }

    if (pageNo === "+") {
      dispatch(
        timesheetActions.getTimesheetlist(
          localStorage.getItem("sheetStatus")
            ? localStorage.getItem("sheetStatus")
            : null,
          localStorage.getItem("sheet_start_date")
            ? localStorage.getItem("sheet_start_date")
            : null,
          localStorage.getItem("sheet_end_date")
            ? localStorage.getItem("sheet_end_date")
            : null,
          localStorage.getItem("tms_search")
            ? localStorage.getItem("tms_search")
            : null,
          localStorage.getItem("sheetComapny")
            ? localStorage.getItem("sheetComapny")
            : null,

          page + 1,
          localStorage.getItem("VehicleCode")
            ? localStorage.getItem("VehicleCode")
            : null
        )
      );
    }
  };

  useEffect(() => {
    // dispatch(timesheetActions.getTimesheetlist());
    localStorage.removeItem("sheetStatus");
    localStorage.removeItem("tms_search");
    localStorage.removeItem("sheet_start_date");
    localStorage.removeItem("sheet_end_date");
    localStorage.removeItem("sheetComapny");
    localStorage.removeItem("VehicleCode");

    dispatch(userActions.getUserData());
    if (timeSheetStatus) {
      handleCallback2(startDate, today, timeSheetStatus);
    } else {
      handleCallback(startDate, today);
    }
  }, []);

  useEffect(() => {
    if (selectedsheetid) {
      if (timesheet_list && timesheet_list.length > 0) {
        let tmpItem;
        timesheet_list.map((mapItem) => {
          if (mapItem.id === parseInt(selectedsheetid)) {
            tmpItem = mapItem;
          }
        });
        if (tmpItem) {
          setOpen(true);
          setTimesheetData(tmpItem);
        } else {
          axios
            .get(
              `${config.apiUrl}/timesheet-by-id-not-in-page/?sheet_id=${selectedsheetid}`,
              getOptions()
            )
            .then((response) => {
              if (response.data.code === 200) {
                if (response.data.data.length !== 0) {
                  let data_dct = response.data.data;

                  if (data_dct && data_dct.length > 0) {
                    setOpen(true);
                    setTimesheetData(data_dct[0]);
                  }
                }
              }
            })
            .catch((err) => {
              return err;
            });
        }
      }
    }
  }, [timesheet_list]);

  return (
    <div className="flex">
      {timesheet_list && timesheet_list.length > 0 ? (
        <Modal
          open={open}
          setOpen={setOpen}
          timesheet_data={TimesheetData}
          selected_sheetid={selectedsheetid}
        />
      ) : null}

      <SideBar />
      <main className="w-full min-w-[90rem] overflow-x-auto bg-gray-100 py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center">
              <div className="space-y-4 sm:flex-auto">
                <h1 className="text-3xl font-semibold leading-6 text-gray-900">
                  Timesheets
                </h1>
                <p className="min-width-350 mt-2 text-sm text-gray-500">
                  Here's an overview of your Timesheets
                </p>
              </div>
              <div className="mt-4 lg:ml-16 lg:mt-0 lg:w-3/4 ">
                <div>
                  <div className="w-full max-w-xs lg:ml-auto">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="search-icon pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                          className="hw-2 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="search-box block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                        placeholder="Search"
                        type="search"
                        onChange={(event) =>
                          HandleSearchChange(event.target.value)
                        }
                        value={searchValue}
                      />
                      {searchValue && (
                        <button
                          type="button"
                          className="cross-icon absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600 text-sm font-bold"
                          onClick={() => HandleSearchChange("")}
                        >
                          ✕
                        </button>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-5 flex items-center space-x-5 gap-10 ">
              {user_role &&
                (user_role === "Approver" || user_role === "CFO" ? (
                  <TimesheetContractor
                    contractorList={companyList && companyList}
                    updatePageNo={updatePageNo}
                  />
                ) : null)}
              <Status updatePageNo={updatePageNo} />
              <VehicleCode
                updatePageNo={updatePageNo}
                vehicleCodeList={vehicle_code_list && vehicle_code_list}
              />
              {/* <SelectedDate /> */}
              <div className="date-range ">
                <DateRangePicker
                  ref={dateRangePickerRef}
                  onCallback={handleCallback}
                  initialSettings={dateRange}
                >
                  <button
                    type="button"
                    className="custom-select-container_calendar "
                  >
                    {handleLongDateString(placeholder, 12)}
                  </button>
                </DateRangePicker>
              </div>
              <button
                onClick={HandleClearFilters}
                type="button"
                style={{borderRadius:'6px'}}
                className="min-w-[10rem] jcc mt-2 inline-flex items-center gap-x-1.5 rounded-md bg-[#FCAF17] px-5 py-[0.45rem] text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FCAF17]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={14}
                  fill="none"
                >
                  <path
                    fill="#fff"
                    d="M5.25 14c-1.486-.194-2.733-.85-3.74-1.969C.503 10.913 0 9.583 0 8.041c0-.847.16-1.635.48-2.364.319-.73.756-1.365 1.312-1.906l1.062 1.062a4.464 4.464 0 0 0-.99 1.438A4.37 4.37 0 0 0 1.5 8.04c0 1.126.358 2.098 1.073 2.917A4.533 4.533 0 0 0 5.25 12.48V14Zm1.5 0v-1.52a4.414 4.414 0 0 0 2.677-1.511c.715-.827 1.073-1.802 1.073-2.927 0-1.25-.438-2.313-1.313-3.188C8.313 3.98 7.25 3.542 6 3.542h-.146l.958.958L5.75 5.542 3 2.792 5.75.042l1.063 1.062-.938.938H6c1.667 0 3.083.583 4.25 1.75C11.417 4.958 12 6.375 12 8.042c0 1.541-.504 2.875-1.51 4C9.483 13.167 8.236 13.819 6.75 14Z"
                  />
                </svg>
                Reset
              </button>
            </div>
            <div className="mt-8 flow-root rounded-lg border border-gray-200 bg-white">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="relative">
                    <table className="min-w-full table-fixed divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="border-r border-gray-200 py-3.5 pl-5 pr-3 text-left text-sm font-normal text-gray-400"
                          >
                            Month
                          </th>
                          <th
                            scope="col"
                            className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                          >
                            Year
                          </th>
                          <th
                            scope="col"
                            className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                          >
                            Foreman
                          </th>
                          <th
                            scope="col"
                            className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                          >
                            Machine
                          </th>
                          <th
                            scope="col"
                            className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                          >
                            Purchase Order
                          </th>
                          <th
                            scope="col"
                            className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                          >
                            Invoice
                          </th>
                          <th
                            scope="col"
                            className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                          >
                            Reference No.
                          </th>
                          {/* <th
                            scope="col"
                            className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                          >
                            Cost Center
                          </th> */}
                          <th
                            scope="col"
                            className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                          >
                            Manager
                          </th>
                          <th
                            scope="col"
                            className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="relative w-16 py-3.5 pl-3 pr-4 sm:pr-3"
                          >
                            <span className="sr-only">Details</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {timesheet_list && timesheet_list.length > 0 ? (
                          timesheet_list.map((item, index) => (
                            <tr key={index}>
                              <td
                                className="whitespace-nowrap py-4 pl-5 pr-3 text-sm text-gray-500"
                                title={item.month}
                              >
                                {getMonthName(item.month)}
                              </td>
                              <td
                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                title={item.year}
                              >
                                {item.year}
                              </td>
                              <td
                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                title={item.foreman}
                              >
                                {item.foreman}
                              </td>
                              <td
                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                title={item.machine}
                              >
                                {handleLongString(item.machine, 18)}
                              </td>
                              <td
                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                title={item.po}
                              >
                                {item.po}
                              </td>
                              <td
                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                title={item.invoice}
                              >
                                {item.invoice}
                              </td>
                              <td
                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                title={item.order_number}
                              >
                                {item.order_number}
                              </td>
                              {/* <td
                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                title={item.cost_center}
                              >
                                {item.cost_center}
                              </td> */}
                              <td
                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                title={item.manager_name}
                              >
                                {item.manager_name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-center text-sm">
                                <div
                                  className={clsx(
                                    item.is_approved === true &&
                                      item.is_auditable === false
                                      ? "bg-green-100 text-green-600"
                                      : item.is_approved === false &&
                                        item.is_auditable === true
                                      ? "bg-yellow-100 text-yellow-600"
                                      : "bg-red-100 text-red-600",
                                    "rounded-lg py-3 "
                                  )}
                                >
                                  {item.is_approved === false &&
                                  item.is_auditable === false
                                    ? `Pending`
                                    : item.is_approved === true &&
                                      item.is_auditable === false
                                    ? `Approved`
                                    : `Clarification Requested`}
                                </div>
                              </td>
                              <td className="mx-auto whitespace-nowrap text-center text-sm font-medium">
                                <button
                                  type="button"
                                  // onClick={() => setOpen(true)}
                                  onClick={() =>
                                    openDocumentModal(item, item.is_approved)
                                  }
                                  className="text-gray-600 hover:text-gray-900"
                                >
                                  {item.is_approved === true &&
                                  item.is_auditable === false ? (
                                    <EyeIcon className="hw-2" />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={22}
                                      height={22}
                                      fill="none"
                                    >
                                      <path
                                        fill="#000"
                                        d="M4.125 17.875h1.008L15.285 7.723l-1.008-1.008L4.125 16.867v1.008Zm14.07-11.137-2.933-2.934.963-.962c.26-.26.58-.39.962-.39s.703.13.963.39l1.008 1.008c.26.26.39.58.39.962s-.13.703-.39.963l-.962.962Zm-.962.962L5.683 19.25H2.75v-2.933L14.3 4.767 17.233 7.7Zm-2.452-.481-.504-.504 1.008 1.008-.504-.504Z"
                                      />
                                    </svg>
                                  )}
                                  {/* <EyeIcon className="hw-2" /> */}
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="whitespace-nowrap py-4 pl-5 pr-3 text-sm text-gray-500"></td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {timesheet_list_loading ? (
                                <span className="fa fa-spinner fa-spin "></span>
                              ) : (
                                `No Data Found`
                              )}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                            <td className="whitespace-nowrap px-3 py-4 text-center text-sm"></td>
                            <td className="mx-auto whitespace-nowrap text-center text-sm font-medium"></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-5 py-3">
              <div className="flex flex-1 justify-between sm:hidden">
                    <a
                        href="#"
                        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      >
                        Previous
                      </a>
                      <a
                        href="#"
                        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      >
                        Next
                      </a>
                  </div>
                <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing{" "}
                      <span className="font-medium">
                        {offset && offset > 0 ? offset : 0}
                      </span>{" "}
                      to{" "}
                      <span className="font-medium">
                        {upto && upto > 0 ? upto : 0}
                      </span>{" "}
                      of{" "}
                      <span className="font-medium">
                        {total && total > 0 ? total : 0}
                      </span>{" "}
                      results
                    </p>
                  </div>
                  <div>
                    <nav
                      className="isolate inline-flex -space-x-px rounded-md"
                      aria-label="Pagination"
                    >
                      <PaginationCustom
                        onClickPrev={() =>
                          page > 1
                            ? (setPage(page - 1), handlePageChange("-"))
                            : null
                        }
                        onClickNext={() =>
                          page < no_of_pages
                            ? (setPage(page + 1), handlePageChange("+"))
                            : null
                        }
                        disabledPrev={page === 1 ? true : false}
                        disabledNext={page === no_of_pages ? true : false}
                      >
                        {renderPageNumbers()}
                      </PaginationCustom>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
