import * as config from "../config/config";
import { authHeader } from "./http.header";
export const MachinesService = {
    getMachinelist,
    getMachineTypesAndSubtypes,
    addMachine,
    getMachineMessagesById,
    getMachineType,
    getMachineImeiNo,
    getVehicleData,
    getMachineDataById,
    deleteMachineData,
};

function getMachinelist(
    selectedType,
    selectedSubtype,
    searchitem,
    company,
    pageNo
) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
        data: selectedType,
    };

    return fetch(
        `${config.apiUrl}/machine/machine-data/?selectedtype=${selectedType}&selectedSubtype=${selectedSubtype}&searchItem=${searchitem}&selectedComapny=${company}&page=${pageNo}`,
        requestOptions
    ).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);

        if (response.status === 200) {
        } else {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function getMachineTypesAndSubtypes() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        `${config.apiUrl}/machine/get-machine-types-subtypes/`,
        requestOptions
    ).then(handleResponse);
}
function addMachine(machine_data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(machine_data),
    };

    return fetch(`${config.apiUrl}/machine/add-machine/`, requestOptions)
        .then(handleResponse)
        .then((timesheet_res) => {
            return timesheet_res;
        });
}

function getMachineMessagesById() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        `${config.apiUrl}/machine/get-machine-message-by-id/`,
        requestOptions
    ).then(handleResponse);
}

function getMachineType() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        `${config.apiUrl}/machine/get-machine-type/`,
        requestOptions
    ).then(handleResponse);
}
function getMachineImeiNo(machineType) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        `${config.apiUrl}/machine/get-machine-imei/?type=${machineType}`,
        requestOptions
    ).then(handleResponse);
}

function getVehicleData(machineType, imeiNo) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        `${config.apiUrl}/machine/get-vehicle-data/?type=${machineType}&imei=${imeiNo}`,
        requestOptions
    ).then(handleResponse);
}
function getMachineDataById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        `${config.apiUrl}/machine/get-vehicle-data-by-id/?id=${id}`,
        requestOptions
    ).then(handleResponse);
}

function deleteMachineData(id) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };

    return fetch(
        `${config.apiUrl}/machine/delete-vehicle-data/?id=${id}`,
        requestOptions
    ).then(handleResponse);
}
