import { anomalieConstants } from "../actions/types/anomalies.constants";

export function AnomalieList(state = {}, actions) {
  switch (actions.type) {
    case anomalieConstants.GET_ANOMALIE_REQUEST:
      return {
        anomalie_loading: true,
        data: null,
      };
    case anomalieConstants.GET_ANOMALIE_SUCCESS:
      return {
        data: actions.anomalie_list.data["data_list"],
        site_type: actions.anomalie_list.data["site_type"],
        status: actions.anomalie_list.data["status_list"],
        document_type: actions.anomalie_list.data["document_type"],
        anomalie_data: actions.anomalie_list.data["data_list"],
        audits_data: actions.anomalie_list.data["audits_data"],
        no_of_pages: actions.anomalie_list.data["no_of_pages"],
        total: actions.anomalie_list.data["total"],
        offset: actions.anomalie_list.data["offset"],
        upto: actions.anomalie_list.data["upto"],
        anomalie_loading: false,
      };
    case anomalieConstants.GET_ANOMALIE_FAILURE:
      return {
        anomalie_loading: false,
        data: null,
      };
    default:
      return state;
  }
}

export function UpdatedAnomalies(state = {}, actions) {
  switch (actions.type) {
    case anomalieConstants.UPDATE_ANOMALIE_REQUEST:
      return {
        anomalie_loading: true,
        data: null,
      };
    case anomalieConstants.UPDATE_ANOMALIE_SUCCESS:
      return {
        data: actions.anom_res.anom_res.data,
        anomalie_loading: false,
      };
    case anomalieConstants.UPDATE_ANOMALIE_FAILURE:
      return {
        anomalie_loading: false,
        data: null,
      };
    default:
      return {
        anomalie_loading: true,
        data: null,
      };
  }
}
