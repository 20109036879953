import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { machineActions } from "../store/actions/machine.action";
import { useDispatch, useSelector } from "react-redux";

function MachinePopUp(props) {
    const [show, setShow] = useState(false);
    const machineId = props.data.id;
    const machineMake = props.data.make;
    const machineName = props.data.name;
    const machineType = props.data.machine_type;
    const machineSubType = props.data.machine_sub_type;
    const machineAge = props.data.machine_age;
    const machineYear = props.data.year;
    const machineModel = props.data.model;
    const machineDescription = props.data.discription;
    const imeiNumber = props.data.imei_no;
    const vehicle_code = props.data.vehicle_code;
    const contractorCompany = props.data.contractor_company;

    const handleClose = () => {
        props.hideMachinePopUp(false);
        setShow(false);
    };
    useEffect(() => {
        setShow(props.ppmodal);
    }, []);

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="wdth-94">
                        <div className=" col-sm-12 col-lg-12 col-xs-12 col-md-12 ">
                            <h4 className="txt-alg-cntr p-0 m-0">
                                Machine detail
                            </h4>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" col-sm-12 col-lg-12 col-xs-12 col-md-12 mt15  d-flex justify-content-center row">
                        <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6">
                            <h5 className="m-l-16px">Machine type</h5>
                            <p className="mr-t-10 clr-gray m-l-16px">
                                {machineType ? machineType : `N/A`}
                            </p>
                        </div>
                        <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6">
                            <h5 className="m-l-16px">Machine subtype</h5>
                            <p className="mr-t-10 clr-gray m-l-16px">
                                {machineSubType ? machineSubType : `N/A`}
                            </p>
                        </div>
                        <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6">
                            <h5 className="m-l-16px">Machine year</h5>
                            <p className="mr-t-10 clr-gray m-l-16px">
                                {machineYear ? machineYear : `N/A`}
                            </p>
                        </div>
                        <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6 ">
                            <h5 className="m-l-16px">Make</h5>
                            <p className=" mr-t-10 clr-gray m-l-16px">
                                {machineMake ? machineMake : `N/A`}
                            </p>
                        </div>

                        <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6">
                            <h5 className="m-l-16px">Machine age</h5>
                            <p className="mr-t-10 clr-gray m-l-16px">
                                {machineAge ? machineAge + " Years" : `N/A`}
                            </p>
                        </div>
                        <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6">
                            <h5 className="m-l-16px">Machine model</h5>
                            <p className="mr-t-10 clr-gray m-l-16px">
                                {machineModel ? machineModel : `N/A`}
                            </p>
                        </div>

                        <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6">
                            <h5 className="m-l-16px">IMEI number</h5>
                            <p className="mr-t-10 clr-gray m-l-16px">
                                {imeiNumber ? imeiNumber : `N/A`}
                            </p>
                        </div>
                        <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6">
                            <h5 className="m-l-16px">Vehicle code</h5>
                            <p className="mr-t-10 clr-gray m-l-16px">
                                {vehicle_code ? vehicle_code : `N/A`}
                            </p>
                        </div>
                        <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6">
                            <h5 className="m-l-16px">Contractor company</h5>
                            <p className="mr-t-10 clr-gray m-l-16px">
                                {contractorCompany ? contractorCompany : `N/A`}
                            </p>
                        </div>

                        <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6">
                            <h5 className="m-l-16px">Machine description</h5>
                            <p className="mr-t-10 clr-gray m-l-16px">
                                {machineDescription
                                    ? machineDescription
                                    : `N/A`}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default MachinePopUp;
