import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { documentActions } from "../../store/actions/document.actions";
import { useDispatch } from "react-redux";
const machineTypes = [
  { id: 1, name: "FEL" },
  { id: 2, name: "Not Specified" },
  { id: 3, name: "WATER TANKER" },
  { id: 4, name: "ADT" },
  { id: 5, name: "EXCAVATOR" },
  { id: 6, name: "DOZER" },
  { id: 7, name: "GRADER" },
];

export function MachineType(mach_type_list) {
  const dispatch = useDispatch()
  const machineList = mach_type_list ? mach_type_list.mach_type_list :[];
  const [selected, setSelected] = useState(null);

  const handleMachineTypeChange = (mach_type) => {
    localStorage.setItem("doc-machine", mach_type);
    dispatch(
      documentActions.getDocumentlist(
        localStorage.getItem("file_type")
          ? localStorage.getItem("file_type")
          : null,
        localStorage.getItem("docComapny")
          ? localStorage.getItem("docComapny")
          : null,
        localStorage.getItem("foreman")
          ? localStorage.getItem("foreman")
          : null,
        mach_type,
        1,
        localStorage.getItem("start_date")
                    ? localStorage.getItem("start_date")
                    : null,
                localStorage.getItem("end_date")
                    ? localStorage.getItem("end_date")
                    : null,
        localStorage.getItem("year") ? localStorage.getItem("year") : null,
        localStorage.getItem("doc_search")
          ? localStorage.getItem("doc_search")
          : null
      )
    );
  };
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-2 min-w-[10rem]">
            <Listbox.Button className="f-btn relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#FCAF17] sm:text-sm sm:leading-6">
              <span className="flex items-center space-x-1.5 truncate">
                {!localStorage.getItem("doc-machine")  && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="none"
                  >
                    <g
                      fill="#657488"
                      fillRule="evenodd"
                      clipPath="url(#a)"
                      clipRule="evenodd"
                    >
                      <path d="M18.573 15.089H6.426c-.762 0-1.377.726-1.377 1.625v1.623c0 .898.614 1.624 1.377 1.624h12.147c.76 0 1.376-.726 1.376-1.623v-1.624c0-.899-.615-1.625-1.377-1.625Zm-9.85 3.792a1.404 1.404 0 0 1-1.393-1.413c0-.784.625-1.416 1.394-1.416.766 0 1.391.632 1.391 1.416 0 .782-.625 1.413-1.391 1.413Zm3.76-.014a1.347 1.347 0 0 1-1.346-1.35c0-.746.603-1.35 1.345-1.35a1.35 1.35 0 0 1 1.346 1.35c0 .747-.604 1.35-1.345 1.35Zm3.806.04c-.789 0-1.432-.618-1.432-1.384 0-.77.643-1.39 1.432-1.39.788 0 1.431.62 1.431 1.39 0 .766-.642 1.384-1.431 1.384ZM14.973 10.026l.026-2.517H9.27L8.23 4.887l-.005-.015-.006.003-3.233-3.37L5.421.38l-1.08-.42L1.52 7.814a2.96 2.96 0 0 0-1.17 3.938c.75 1.433 2.878 2.408 4.3 1.644 1.101-.59-.65-4.256-1.986-5.336l1.86-5.358 2.703 2.82 1.602 5.73v1.245c0 2.01 1.288 1.18 1.288 1.18h7.539s1.055.488 1.055-1.258v-1.524c0-1.084-1.172-.885-1.172-.885l-2.566.016Zm-1.184 2.552H9.92V8.67h3.868v3.907Z" />
                    </g>
                    <defs>
                      <clipPath id="a">
                        <path fill="#fff" d="M0 0h20v20H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
                <span className="fs-14">{localStorage.getItem("doc-machine")
          ? localStorage.getItem("doc-machine")  : "Machine Type"}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="hw-2 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-dp w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm fs-14">
                {machineList && machineList.length>0 ? machineList.map((item) => (
                  <Listbox.Option
                  title={item.label}
                    key={item.label}
                    className={({ active }) =>
                      clsx(
                        active ? "bg-gray-50" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item.value}
                    onClick={() =>handleMachineTypeChange(item.value)}

                  >
                    {({ selected }) => (
                      <div className="flex items-center space-x-2">
                        <span
                          className={clsx(
                            selected && localStorage.getItem("doc-machine") ? "bg-[#FCAF17]" : "bg-gray-200",
                            "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                          )}
                        />
                        <span
                          className={clsx(
                            selected && localStorage.getItem("doc-machine") ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item.value}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                )):null
                }
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
