import { useState, useEffect, useRef } from "react";
import SideBar from "./SideBar";
import pdf from "../assets/pdfs/YellowMachineHire.pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus, faSearchMinus } from "@fortawesome/free-solid-svg-icons";

export default function HelpAndSupport() {
  const isNotFirefox = !navigator.userAgent.toLowerCase().includes("firefox");
  const isSafari =
    /^((?!chrome|android|crios|fxios|opera|msie|trident).)*safari.*$/i.test(
      navigator.userAgent
    );

  const [zoom, setZoom] = useState(100);
  const handleZoomIn = () => {
    const newZoom = zoom + 5;
    setZoom(Math.min(170, newZoom)); // Limit the zoom to a maximum of 170%
  };

  const handleZoomOut = () => {
    const newZoom = zoom - 5;
    setZoom(Math.max(90, newZoom)); // Limit the zoom to a minimum of 90%
  };

  return (
    <div className="flex">
      {/* Sidebar component (replace with your implementation) */}
      <SideBar />

      <main className="w-full  overflow-x-auto bg-gray-100 py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center">
              <div className="space-y-4 sm:flex-auto">
                <h1 className="text-3xl font-semibold leading-6 text-gray-900">
                  Help & Support
                </h1>
              </div>
            </div>

            <div className="bg-color-gray-black mt-8 flow-root rounded-lg border border-gray-200 ">
              {isNotFirefox && !isSafari && (
                <div className="zoom-mrgn txt-alg-cntr zoom-btns">
                  <button
                    className="mar-rght-5px zoom-mrgn"
                    onClick={handleZoomIn}
                  >
                    Zoom In <FontAwesomeIcon icon={faSearchPlus} />
                  </button>
                  |
                  <button
                    className="mar-left-5px zoom-mrgn"
                    onClick={handleZoomOut}
                  >
                    Zoom Out <FontAwesomeIcon icon={faSearchMinus} />
                  </button>
                </div>
              )}
              <div
                style={{
                  transform: `scale(${zoom / 100})`,
                  transformOrigin: "top left",
                  height: `${(100 * 100) / zoom}%`, // Adjust the height to fit the zoomed content
                  width: `${(100 * 100) / zoom}%`, // Adjust the width to fit the zoomed content
                }}
              >
                <iframe
                  src={`${pdf}#toolbar=0`}
                  title="PDF Viewer"
                  width="100%"
                  height="800px"
                  frameBorder="0"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
