import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus, faSearchMinus } from "@fortawesome/free-solid-svg-icons";
let pdfIsLoaded = false;

export function ShowPdfFile() {
  const isNotFirefox = !navigator.userAgent.toLowerCase().includes("firefox");
  const isSafari =
    /^((?!chrome|android|crios|fxios|opera|msie|trident|firefox).)*safari.*$/i.test(
      navigator.userAgent
    );

  const [zoom, setZoom] = useState(100);
  const handleZoomIn = () => {
    const newZoom = zoom + 5;
    setZoom(Math.min(170, newZoom)); // Limit the zoom to a maximum of 170%
  };

  const handleZoomOut = () => {
    const newZoom = zoom - 5;
    setZoom(Math.max(90, newZoom)); // Limit the zoom to a minimum of 90%
  };

  useEffect(() => {
    if (!pdfIsLoaded) {
      const pdfData = window.pdfData;

      if (!pdfData) {
        console.error("Error: PDF data not found");
        return;
      }

      const binaryData = atob(pdfData);
      const uint8Array = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(blob);

      const iframe = document.querySelector("iframe");
      iframe.src = pdfUrl;
      iframe.src = pdfUrl + "#toolbar=0&navpanes=0&scrollbar=0";

      // iframe.setAttribute("sandbox", "allow-scripts allow-same-origin"); // Add this to restrict features

      pdfIsLoaded = true; // Set the flag to true
    }
  }, []);

  return (
    <div className="flex">
      <SideBar />
      <main className="w-full overflow-x-auto bg-gray-100 py-10">
        <div className="h-95-percent px-4 sm:px-6 lg:px-8">
          <div className="h-full  px-4 sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center">
              <div className="space-y-4 sm:flex-auto">
                <h1 className="text-3xl font-semibold leading-6 text-gray-900">
                  Timesheet PDF File
                </h1>
              </div>
            </div>

            <div className="bg-color-gray-black mt-8 flow-root h-full rounded-lg border border-gray-200">
              {isNotFirefox && !isSafari && (
                <div className="zoom-mrgn txt-alg-cntr zoom-btns">
                  <button
                    className="mar-rght-5px zoom-mrgn"
                    onClick={handleZoomIn}
                  >
                    Zoom In <FontAwesomeIcon icon={faSearchPlus} />
                  </button>
                  |
                  <button
                    className="mar-left-5px zoom-mrgn"
                    onClick={handleZoomOut}
                  >
                    Zoom Out <FontAwesomeIcon icon={faSearchMinus} />
                  </button>
                </div>
              )}
              <div
                style={{
                  transform: `scale(${zoom / 100})`,
                  transformOrigin: "top left",
                  height: `${(100 * 100) / zoom}%`, // Adjust the height to fit the zoomed content
                  width: `${(100 * 100) / zoom}%`, // Adjust the width to fit the zoomed content
                }}
              >
                <iframe title="PDF Viewer" width="100%" height="95%" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
