import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { anomalieActions } from "../../store/actions/anomalies.actions";
import { useDispatch } from "react-redux";


export function AnomaliesStatusFilter({statusTypes,updatePageNo}) {
  const dispatch = useDispatch();
  const status_types = statusTypes && statusTypes !== 'undefined' && statusTypes !== undefined ?statusTypes:[]
  
  const handleOptionClick = (anm_type) => {
    updatePageNo(1);
      localStorage.setItem(
        "anm_status",
        anm_type
    );
    dispatch(
      anomalieActions.getAnomalielist(
        null,
        null,
        anm_type,
        localStorage.getItem("anm_start_date")
            ? localStorage.getItem("anm_start_date")
            : null,
        localStorage.getItem("anm_end_date")
            ? localStorage.getItem("anm_end_date")
            : null,
        localStorage.getItem("anmComapny")
            ? localStorage.getItem("anmComapny")
            : null,
        1,
        localStorage.getItem("anom_search")
          ? localStorage.getItem("anom_search")
          : null
    )
  );
  }
  const [selected, setSelected] = useState(null);
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-2 min-w-[10rem]">
            <Listbox.Button className="f-btn relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#FCAF17] sm:text-sm sm:leading-6">
              <span className="flex items-center space-x-1.5 truncate">
                {!localStorage.getItem("anm_status") && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M7 15h6v-1.5H7V15Zm0-3h6v-1.5H7V12Zm-1.506 6c-.413 0-.765-.147-1.056-.44A1.45 1.45 0 0 1 4 16.5v-13c0-.413.147-.766.44-1.06.294-.293.647-.44 1.06-.44H12l4 4v10.5c0 .413-.147.766-.44 1.06-.295.293-.648.44-1.06.44H5.493ZM11 7V3.5H5.5v13h9V7H11Z"
                    />
                  </svg>
                )}
                <span className="fs-14">{localStorage.getItem("anm_status")
                        ? localStorage.getItem("anm_status")
                        :  "Status"}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="hw-2 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="fs-14 absolute z-10 mt-1 max-h-dp w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {status_types && status_types.length>0 ?status_types.map((item) => (
                  <Listbox.Option
                    title={item.label}
                    key={item.label}
                    className={({ active }) =>
                      clsx(
                        active ? "bg-gray-50" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item.value}
                    onClick={() => handleOptionClick(item.value)}
                  >
                    {({ selected }) => (
                      <div className="flex items-center space-x-2">
                        <span
                          className={clsx(
                            selected && localStorage.getItem("anm_status")? "bg-[#FCAF17]" : "bg-gray-200",
                            "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                          )}
                        />
                        <span
                          className={clsx(
                            selected && localStorage.getItem("anm_status") ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item.value}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                )):null}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
