import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../store/actions/user.actions";
import { history } from "../helpers/history";
import Login from "../assets/images/login2.png";
import Microsoft from "../assets/images/microsoft.png";
import { loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";

function LoginPageNew() {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const { email, password } = inputs;
  const loggingIn = useSelector((state) => state.authentication.loggingIn);
  const error = useSelector((state) => state.authentication.message);

  const { accounts, inProgress, instance } = useMsal();
  const [isMicroAccount, setIsMicroAccount] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (inProgress === "none") {
      instance.handleRedirectPromise().then((response) => {
        if (response) {
          setIsMicroAccount(true);
        }
      }).catch((error) => {
        console.error("MSAL Redirect Error:", error);
      });
    }
  }, [inProgress, instance]);


  const handleLogin = async () => {
    try {
      await instance.loginPopup(loginRequest);
      setIsMicroAccount(true);
    } catch (error) {
      console.error("Popup Login Error:", error.message || error);
    }
  };

  useEffect(() => {
    if (accounts.length > 0 && isMicroAccount) {
      const currentUser = accounts[0];
      const { from } = { from: { pathname: "/" } };
      dispatch(userActions.MicrosoftLogin(currentUser["username"], from));

      if (localStorage.getItem("token")) {
        history.push("/");
      }
    }
  }, [accounts, isMicroAccount, dispatch]);
  

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (email && password) {
      const { from } = { from: { pathname: "/" } };
      dispatch(userActions.login(email, password, from));
    }
  }

  return (
    <div className="flex h-screen flex-1">
      <div className="relative w-1/2 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={Login}
          alt="login"
        />
      </div>
      <div className="flex w-1/2 flex-1 flex-col justify-center lg:flex-none">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={91}
              height={50}
              fill="none"
            >
              <g clipPath="url(#a)">
                <path
                  fill="#FCAF17"
                  d="m44.489.054-16.923 24.57h6.705L44.49 9.784V.055Zm8.994 24.57 9.95-14.463V.431L46.778 24.623h6.706Zm-9.473 0 9.951-14.463V.431L37.305 24.623h6.705Z"
                />
                <path
                  fill="#000"
                  d="M90.415 24.624 73.492 0 56.516 24.624h33.899ZM42.84 42.85V49.3h4.842v.753h-6.014v-7.205h1.171ZM50.502 42.85v7.204h-1.224v-7.205h1.224ZM52.418 50.054v-7.205h1.543l3.406 6.022 3.353-6.022h1.437v7.205h-1.065v-5.807l-3.3 5.807h-.957l-3.353-5.807v5.807h-1.064ZM65.296 42.85v7.204h-1.224v-7.205h1.224ZM73.279 42.85v.752h-2.927v6.452h-1.17v-6.452h-2.928v-.752h7.025ZM80.303 42.85v.752h-4.842v2.42h4.63v.752h-4.63v2.527h4.949v.753h-6.173v-7.205h6.067ZM82.166 50.054v-7.205h3.778c2.874 0 4.311 1.13 4.311 3.388 0 2.526-1.437 3.817-4.31 3.817h-3.779Zm3.512-.753c2.289 0 3.406-1.021 3.406-3.064 0-1.775-1.117-2.635-3.406-2.635H83.39v5.7h2.288ZM.053 38.925v-10.27h5.641c4.098 0 6.12 1.614 6.12 4.84 0 3.602-2.022 5.43-6.12 5.43H.054Zm5.322-1.667c2.714 0 4.044-1.236 4.044-3.763 0-2.15-1.33-3.226-4.044-3.226H2.5v6.989h2.874ZM14.102 38.925v-10.27h5.907c2.661 0 3.992.915 3.992 2.743 0 1.236-1.012 2.258-3.087 3.064l4.15 4.463h-3.139l-3.938-4.516v-.86c2.395-.323 3.566-1.022 3.566-2.097 0-.753-.533-1.13-1.65-1.13h-3.3v8.603h-2.5ZM26.661 38.925v-10.27h5.641c4.098 0 6.12 1.614 6.12 4.84 0 3.602-2.022 5.43-6.12 5.43h-5.64Zm5.322-1.667c2.714 0 4.045-1.236 4.045-3.763 0-2.15-1.331-3.226-4.045-3.226h-2.874v6.989h2.874Z"
                />
                <path
                  fill="#FCAF17"
                  d="M51.14 38.44c-1.063.377-2.394.538-3.937.538-4.63 0-6.918-1.774-6.918-5.376 0-3.387 2.341-5.054 7.024-5.054 1.437 0 2.714.162 3.832.484v1.72a11.44 11.44 0 0 0-3.672-.59c-3.14 0-4.683 1.128-4.683 3.44 0 2.473 1.49 3.71 4.417 3.71.426 0 .958-.054 1.49-.161V34.14h2.395v4.3h.053ZM52.95 33.763c0-3.494 2.182-5.215 6.546-5.215 4.31 0 6.44 1.72 6.44 5.215 0 3.441-2.13 5.215-6.44 5.215-4.15 0-6.333-1.72-6.546-5.215Zm6.546 3.549c2.607 0 3.938-1.183 3.938-3.602 0-2.366-1.33-3.549-3.938-3.549-2.714 0-4.044 1.183-4.044 3.549 0 2.419 1.383 3.602 4.044 3.602ZM70.671 28.656v8.656h6.12v1.666h-8.514V28.656h2.394ZM79.08 38.925v-10.27h5.64c4.098 0 6.12 1.614 6.12 4.84 0 3.602-2.022 5.43-6.12 5.43h-5.64Zm5.268-1.667c2.714 0 4.044-1.236 4.044-3.763 0-2.15-1.33-3.226-4.044-3.226h-2.874v6.989h2.874Z"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" d="M0 0h91v50H0z" />
                </clipPath>
              </defs>
            </svg>
            <h2 className="mt-12 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Welcome
            </h2>
          </div>

          <div className="mt-4">
            <div>
              <form name="form" className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Username<span class="required-field">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      //   required
                      value={email}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password<span class="required-field">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      //   required
                      value={password}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-[#FCAF17] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FCAF17]"
                  >
                    {loggingIn && (
                      <span className="fa fa-spinner fa-spin mr-1 mt-1"></span>
                    )}
                    Sign in
                  </button>
                </div>
                {error ? (
                  <div className="alert alert-danger">{error}</div>
                ) : null}
                {submitted && !email && (
                  <div className="alert alert-danger">Email is required</div>
                )}
                {submitted && !password && (
                  <div className="alert alert-danger">Password is required</div>
                )}
              </form>
            </div>
            <div class="or-container">
              <div class="line-separator"></div>
              <div class="or-label">or</div>
              <div class="line-separator"></div>
            </div>
            <button
              className="microsoft-login-button"
              onClick={() => handleLogin()}
            >
              <img className="button-icon" src={Microsoft} alt="login" />
              <span className="button-text">
                Login with DRD Account (Microsoft)
              </span>
            </button>
            {/* <button onClick={handleLogout}>Logout</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export { LoginPageNew };
