import * as config from "../config/config";
import { authHeader } from "./http.header";
export const DocumentService = {
  getDocumentlist,
};

function getDocumentlist(
  fileType,
  companyType,
  foremanType,
  machineType,
  page,
  start_date,
  end_date,
  year,
  doc_search
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/document/document-data/?companyType=${companyType}&machineType=${machineType}&foremanType=${foremanType}&fileType=${fileType}&page_no=${page}&start_date=${start_date}&end_date=${end_date}&year=${year}&doc_search=${doc_search}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (response.status === 200) {
    } else {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
